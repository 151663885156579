import React, { ReactElement } from 'react'
import { AppFC } from 'types'
interface ITabProps {
  name: string
  title: string
  isEnabled?: boolean
  component: ReactElement
}
export const Tab: AppFC<ITabProps> = ({ component: Component }) => {
  return (
    <div className="xs:bock bg-white grid-cols-12 pb-6 lg:pb-[24px] mdl:mb-[24px] lg:mb-[37px] gap-12 shadow-lg  mdl:pb-6 xl:pb-10">
      {Component}
    </div>
  )
}

interface IClientTabsProps extends React.PropsWithChildren<{}> {
  activeTab: string
  onChange: (payload?: string) => void
}

export const TabsPanel: AppFC<IClientTabsProps> = ({ activeTab, onChange, children }) => {
  const handleTabChange = (activeTab) => {
    onChange(activeTab)
  }

  return (
    <div>
      <ul className="flex bg-white max-w-full overflow-auto shadow-md relative">
        {React.Children.map(children as React.ReactElement, (child, index) => {
          const { title, isEnabled = true, name } = child.props

          if (isEnabled) {
            return (
              <li
                key={index}
                className={`${
                  activeTab === name
                    ? 'active !font-bold border-b-4 border-primary !text-primary'
                    : 'font-normal'
                } cursor-pointer whitespace-nowrap leading-7 w-full text-center sm:text-lg py-5 px-3 font-primary xs:text-base lg:text-xl hover:border-b-4 hover:border-primary hover:pb-5 hover:!pt-5`}
                onClick={() => handleTabChange(name)}
              >
                {title}
              </li>
            )
          } else {
            return null
          }
        })}
      </ul>
      <div className="tab-content">
        {React.Children.map(children as React.ReactElement, (child, index) => {
          if (activeTab === child.props.name) {
            return child
          }
          return null
        })}
      </div>
    </div>
  )
}
