import React, { FC, useEffect, useState } from 'react'
import { Control, Controller, FieldValues, useForm } from 'react-hook-form'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import Button from 'components/Common/Button'
import InputField from 'components/Common/InputField'
import InputTextArea from 'components/Common/InputTextArea'
import { EditorWrapper } from 'components/Calendar/Meetings/style'

import { isEmpty } from 'lodash'
import { CALENDAR, FORM, MEETING_LABEL, MEETING_STEPPER } from 'config'
import hookForms from 'utils/hookForms'
import useFormContainer from 'hooks/useFormContainer'
import {
  Divider,
  FormWrapper,
  IconWithLabel,
  InputFieldsWrapper,
  RequestMeetingWrapper,
} from 'components/Calendar/style'
import MeetingSettingsEditor, {
  MeetingSettingsEditorStyle,
} from '../../Common/MeetingSettingsEditor'
import { getRoleFromPath, meetingFormChangedValues } from 'utils/helper'
import { meetingsSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import { ampli } from 'ampli'
import Label from 'components/Common/Label'
import { ContentState, EditorState, convertFromHTML } from 'draft-js'
import uuid from 'react-uuid'
import NameInitials from 'components/Common/NameInitials'
import ProfileIcon from 'components/Common/SvgIcons/ProfileIcon'
import LinkIcon from 'components/Common/SvgIcons/LinkIcon'
import MapIcon from 'components/Common/SvgIcons/MapIcon'
import DescriptionIcon from 'components/Common/SvgIcons/DescriptionIcon'
import { UserBasicInfo, extendedCalendarEvents } from 'types'
import { UserProfile } from '__generated__/api-types-and-hooks'

interface IIconWithInitialsProps {
  user: UserBasicInfo
}

interface IVideoMeetingLinkProps {
  control: Control<FieldValues>
  videoLinkDescription: string
  videoLinkKey: string
  showEditButton: boolean
  isEdit: boolean
  setEditVideoMeetingLink: (param) => void
  editVideoMeetingLink: boolean
}
interface IMeetingLocationProps {
  control: Control<FieldValues>
  isEdit: boolean
  location: string
  showEditButton: boolean
}

interface IMeetingDetailsProps {
  selectedUser?: UserBasicInfo
  user: UserProfile
  event?: extendedCalendarEvents
  isEdit: boolean
  onSubmit: () => void
  closeModal?: () => void
  isDisabled: boolean
  description: EditorState
  setDescription: (param) => void
  showEditButton?: boolean
  setShowModalBox?: (param) => void
  setShowEditButton?: (param) => void
  updateMeetingStepperAction?: (input: { actionType: string; stepName: MEETING_STEPPER }) => void
  videoLinkDescription: string
}
const MeetingDetails: FC<IMeetingDetailsProps> = ({
  selectedUser,
  user,
  event,
  isEdit,
  onSubmit,
  closeModal,
  isDisabled,
  description,
  setDescription,
  showEditButton = false,
  setShowModalBox,
  setShowEditButton,
  updateMeetingStepperAction,
  videoLinkDescription,
}) => {
  const [videoLinkKey, setVideoLinkKey] = useState(uuid())
  const [showAdditionalGuest, setShowAdditionalGuest] = useState(false)
  const [showLocation, setShowLocation] = useState(false)
  const [editVideoMeetingLink, setEditVideoMeetingLink] = useState(true)
  const userRole = getRoleFromPath()
  const isExternalCalendar = event?.eventType ? event.eventType !== CALENDAR.INTERNAL : false
  // const requestedByBusinessOwner = event?.ownerId === event?.inviteeId
  // {requestedByBusinessOwner ? 'Advisor' : 'Client'} name

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(meetingsSchema),
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods
  const form = hookForms.getForm(FORM.MEETING_SCHEDULED)
  const meetingForm = form?.getValues()

  useEffect(() => {
    setShowEditButton && setShowEditButton(true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    reset({
      meetingLocation: event?.location,
      videoLinkDescription: event?.videoLinkDescription ?? videoLinkDescription ?? '',
      attendees: event?.attendeesEmail?.join(', '),
    })
    setVideoLinkKey(uuid())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditButton])

  useFormContainer(FORM.MEETING_SCHEDULED, methods)
  const handleDescriptionChange = (description) => {
    setDescription(description)
  }

  const participant = {
    firstName: event?.participantDetail?.firstName
      ? event?.participantDetail?.firstName
      : selectedUser?.firstName,
    lastName: event?.participantDetail?.lastName
      ? event?.participantDetail?.lastName
      : selectedUser?.lastName,
  }
  const organizer = {
    firstName: event?.organizerDetail?.firstName
      ? event?.organizerDetail?.firstName
      : user.firstName,
    lastName: event?.organizerDetail?.lastName ? event?.organizerDetail?.lastName : user.lastName,
  }

  return (
    <FormWrapper className={`${isEdit ? 'px-6' : 'px-0'} `} onSubmit={handleSubmit(onSubmit)}>
      <RequestMeetingWrapper className={'!pt-6 !pb-0'}>
        <InputFieldsWrapper>
          <div className={showEditButton ? 'cursor-not-allowed' : ''}>
            <div>
              <IconWithLabel>
                <ProfileIcon
                  className={
                    'mb-auto inline mr-3 fill-primary-disabled hover:!fill-primary-disabled'
                  }
                />
                <div className="flex flex-col ">
                  <Label fontSize={'base'} fontWeight={'font-medium'}>
                    Meeting attendees:
                  </Label>
                  {!isExternalCalendar && <IconWithInitials user={participant} />}
                  <IconWithInitials user={organizer} />
                </div>
              </IconWithLabel>

              {showEditButton && !isEmpty(event?.attendeesEmail) && (
                <div className="ml-12">
                  <div className="pl-12 pt-2">
                    <p>Additional guests</p>

                    {event?.attendeesEmail.map((email, index) => (
                      <p key={index} className="break-words">
                        {email}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* )} */}
            <div className="pl-9">
              {!showEditButton && (
                <Button
                  label="Add additional guests"
                  variant="text"
                  className={`${showAdditionalGuest ? 'mt-6' : 'my-6'} focus:!border-0`}
                  type="button"
                  onClick={() => setShowAdditionalGuest(!showAdditionalGuest)}
                />
              )}
              {(showEditButton ? false : showAdditionalGuest) && (
                <Controller
                  name="attendees"
                  control={control}
                  defaultValue={isEdit ? event?.attendeesEmail?.join(', ') : ''}
                  render={({ field }) => (
                    <InputTextArea
                      errors={errors.attendees}
                      {...field}
                      label={`${showAdditionalGuest && isEdit ? 'Additional guests' : ''}`}
                      rows={1}
                      placeholder="Enter email address of guest"
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled={showEditButton}
                      className={'text-black-light mt-6 !h-fit mb-8'}
                      labelFontWeight={'font-medium'}
                      labelFontSize="base"
                      textAreaClasses={'!bg-secondary'}
                    />
                  )}
                />
              )}
            </div>
            <div className="pt-2">
              <Divider />
            </div>

            {/* Video meeting link */}

            <div className="pt-6">
              {(showEditButton ? event?.videoLinkDescription || videoLinkDescription : true) && (
                <>
                  <VideoMeetingLink
                    control={control}
                    videoLinkDescription={event?.videoLinkDescription ?? videoLinkDescription ?? ''}
                    videoLinkKey={videoLinkKey}
                    showEditButton={showEditButton}
                    isEdit={isEdit}
                    setEditVideoMeetingLink={setEditVideoMeetingLink}
                    editVideoMeetingLink={editVideoMeetingLink}
                  />
                </>
              )}
            </div>

            {/* Meeting location */}

            {!showEditButton && (
              <div className="flex flex-col w-fit">
                <IconWithLabel>
                  <MapIcon
                    className={`${
                      showLocation ? 'mt-6' : ''
                    } inline mr-3 fill-primary-disabled hover:!fill-primary-disabled`}
                  />
                  <Button
                    label="Add Location"
                    variant="text"
                    className={`${showLocation ? 'mt-6' : 'my-6'} focus:!border-0`}
                    type="button"
                    onClick={() => setShowLocation(!showLocation)}
                  />
                </IconWithLabel>
              </div>
            )}

            {(showEditButton ? event?.location : showLocation) && (
              <IconWithLabel display={showEditButton ? 'block' : ''}>
                {showEditButton && (
                  <>
                    <MapIcon
                      className={`
                       inline mr-3 fill-primary-disabled hover:!fill-primary-disabled`}
                    />

                    <Label fontSize={'base'} fontWeight={'font-medium'}>
                      Add Location
                    </Label>
                  </>
                )}

                <div className="flex flex-col w-full pl-10">
                  <MeetingLocation
                    control={control}
                    isEdit={isEdit}
                    location={event?.location || ''}
                    showEditButton={showEditButton || false}
                  />
                </div>
              </IconWithLabel>
            )}

            {!isEdit && (
              <div className={`${showLocation ? 'pt-2' : ''}`}>
                <IconWithLabel>
                  <DescriptionIcon
                    className={
                      'mb-auto inline mr-3 fill-primary-disabled hover:!fill-primary-disabled'
                    }
                  />
                  <div className="flex flex-col w-full">
                    <Label fontSize={'base'} fontWeight={'font-medium'}>
                      Title of meeting <span className="text-primary-error">*</span>
                    </Label>
                    <Controller
                      name="meetingTitle"
                      control={control}
                      defaultValue={isEdit ? event?.title : ''}
                      render={({ field }) => (
                        <InputField
                          {...field}
                          className={
                            'xs:text-base sm:text-xl col-span-12 pb-2 !font-primary text-black-light !rounded'
                          }
                          placeholder="Enter Meeting Title"
                          readonly={showEditButton}
                        />
                      )}
                    />
                  </div>
                </IconWithLabel>
              </div>
            )}

            {/* Description and additional details  */}

            {(showEditButton ? event?.description : true) && (
              <div className="w-full mt-6">
                <IconWithLabel>
                  <DescriptionIcon
                    className={
                      'mb-auto inline mdl:mt-[3px] mr-3 fill-primary-disabled hover:!fill-primary-disabled'
                    }
                  />
                  <div className="flex flex-col w-full">
                    <EditorWrapper>
                      <Label fontSize={'base'} fontWeight={'font-medium'}>
                        Description and additional details
                      </Label>

                      <Editor
                        editorState={description}
                        webDriverTestID="description"
                        wrapperClassName={`${
                          showEditButton ? 'demo-wrapper-readonly' : 'demo-wrapper'
                        }`}
                        editorClassName={`${
                          showEditButton ? 'demo-editor-readonly' : 'demo-editor'
                        }`}
                        toolbarClassName={`${showEditButton ? 'demo-toolbar-readonly' : ''}`}
                        onEditorStateChange={handleDescriptionChange}
                        readOnly={showEditButton}
                      />
                    </EditorWrapper>
                  </div>
                </IconWithLabel>
              </div>
            )}
          </div>
          <div className="flex md:justify-end">
            <div className="xs:w-full md:w-fit">
              {!showEditButton && (
                <div className="flex gap-3 xs:flex-col-reverse xs:flex-col sm:flex-row w-full items-center">
                  <Button
                    label={MEETING_LABEL.CANCEL}
                    type="button"
                    disabled={isDisabled}
                    variant={`${isEdit ? 'text' : 'secondary'}`}
                    className="xs:w-3/6 md:w-fit"
                    onClick={() => {
                      updateMeetingStepperAction &&
                        updateMeetingStepperAction({
                          actionType: 'reset',
                          stepName: MEETING_STEPPER.ADVISOR,
                        })

                      setShowAdditionalGuest(false)
                      setShowLocation(false)
                      if (!isEdit) {
                        closeModal && closeModal()
                        setShowModalBox && setShowModalBox(false)
                      } else {
                        setShowEditButton && setShowEditButton(true)
                        setDescription(
                          EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(event?.description || '<p></p>').contentBlocks
                            )
                          )
                        )
                        reset({
                          meetingLocation: event?.location,
                          videoLinkDescription:
                            videoLinkDescription ?? event?.videoLinkDescription ?? '',
                          attendees: event?.attendeesEmail?.join(', '),
                        })
                      }
                    }}
                  />

                  <Button
                    label={isEdit ? MEETING_LABEL.SAVE : MEETING_LABEL.CREATE_MEETING}
                    status={isDisabled ? 'loading' : ''}
                    type="Submit"
                    variant="primary"
                    className="xs:w-full md:w-fit"
                    disabled={(!isEdit && isEmpty(meetingForm?.meetingTitle)) || isDisabled}
                    onClick={() => {
                      if (isEdit && event) {
                        ampli.meetingUpdated({
                          initiatorRole: userRole,
                          initiatorName: user?.firstName + ' ' + user?.lastName,
                          ...meetingFormChangedValues(meetingForm, event, description),
                        })
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </InputFieldsWrapper>
      </RequestMeetingWrapper>
    </FormWrapper>
  )
}
MeetingDetails.defaultProps = {
  isEdit: false,
  isDisabled: false,
  showEditButton: false,
  description: undefined,
  onSubmit: () => {},
  closeModal: () => {},
  setDescription: () => {},
  // cancelEventHandler: () => {},
  updateMeetingStepperAction: () => {},
  setShowModalBox: (param) => {},
  setShowEditButton: () => {},
  selectedUser: undefined,
  event: undefined,
}
export default MeetingDetails

export const IconWithInitials: FC<IIconWithInitialsProps> = ({ user }) => {
  return (
    <div className="flex gap-3 items-center mt-3">
      <div>
        <NameInitials
          name={{
            firstName: user.firstName || '',
            lastName: user.lastName || '',
          }}
        />
      </div>
      <span className="text-lg font-bold">
        {user.firstName} {user.lastName}
      </span>
    </div>
  )
}

export const VideoMeetingLink: FC<IVideoMeetingLinkProps> = ({
  control,
  videoLinkDescription,
  videoLinkKey,
  showEditButton,
  isEdit,
  setEditVideoMeetingLink,
  editVideoMeetingLink,
}) => {
  return (
    <div className="text-base sm:text-lg my-2 flex items-center">
      <LinkIcon
        className={'mb-auto inline mr-3 fill-primary-disabled hover:!fill-primary-disabled'}
      />
      <div className="w-full">
        <div className="flex justify-between">
          <Label fontSize={'base'} fontWeight={'font-medium'}>
            Video meeting link
          </Label>
          {!isEdit && videoLinkDescription && (
            <div>
              <Button
                label="Edit"
                variant="text"
                type="button"
                className="w-[50px]"
                onClick={() => {
                  console.log('clicked')
                  setEditVideoMeetingLink(!editVideoMeetingLink)
                }}
              />
            </div>
          )}
        </div>

        <Controller
          name={'videoLinkDescription'}
          control={control}
          defaultValue={videoLinkDescription}
          render={({ field }) => (
            <MeetingSettingsEditor
              {...field}
              key={videoLinkKey}
              style={MeetingSettingsEditorStyle.SECONDARY}
              disabled={!isEdit && videoLinkDescription ? editVideoMeetingLink : showEditButton}
            />
          )}
        />
      </div>
    </div>
  )
}

export const MeetingLocation: FC<IMeetingLocationProps> = ({
  control,
  isEdit,
  location,
  showEditButton,
}) => {
  return (
    <div>
      <Controller
        name="meetingLocation"
        control={control}
        defaultValue={isEdit ? location : ''}
        render={({ field }) => (
          <InputField
            {...field}
            placeholder="Enter Location"
            // label={MEETING_LABEL.LOCATION}
            className={`col-span-12 !font-medium !text-base !font-primary text-black-light ${
              showEditButton ? 'bg-white border-none !px-0 !mt-2' : '!rounded'
            }`}
            readonly={showEditButton}
            labelFontWeight={`${showEditButton ? 'font-bold' : 'font-medium'}`}
            labelFontSize="base"
          />
        )}
      />
    </div>
  )
}
