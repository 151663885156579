import React, { useEffect, useState } from 'react'

import { USER_ROLES } from 'config'
import ModalBox from 'components/Common/ModalBox'
import useToggleButton from 'hooks/useToggleButton'
import { useFlashParams } from 'hooks/useFlashParams'
import ToggleButton from 'components/Common/ToggleButton'
import { AdminRoleErrorWrapper } from 'components/Admin/UserManagement/style'

const AdminRightsToggle = ({
  params,
  isUserAddedToGroup,
  addUserToGroupAction,
  resetIsUserAddedAction,
}) => {
  const [enabled, setEnabled] = useState(false)
  const [isToggled, setIsToggled] = useState(false)
  const { flashParams, removeFlashParams } = useFlashParams<{ message?: string }>()

  useToggleButton(
    enabled,
    isToggled,
    setEnabled,
    setIsToggled,
    isUserAddedToGroup,
    resetIsUserAddedAction
  )

  const userPermissionHandler = () => {
    if (enabled) {
      addUserToGroupAction({ userId: params.userId, remove: true })
    } else {
      addUserToGroupAction({ userId: params.userId, remove: false })
    }
  }

  useEffect(() => {
    if (params && params.role) {
      let isAdmin = false
      params?.role?.some((role) => {
        if (role === USER_ROLES.BSO_ADMIN) {
          setEnabled(true)
          isAdmin = true
          return true
        }
        return false
      })

      if (!isAdmin) {
        setEnabled(false)
      }
    } else {
    }
  }, [params])

  return (
    <>
      {flashParams?.message === 'Unable To Give Admin Role' && (
        <ModalBox rounded="3xl" margin="mx-6" onClose={() => removeFlashParams(['message'])}>
          <AdminRoleErrorWrapper>{flashParams?.message}</AdminRoleErrorWrapper>
        </ModalBox>
      )}

      <div className="col-span-2">
        <ToggleButton
          enabled={enabled}
          toggleButtonHandler={userPermissionHandler}
          setIsToggled={setIsToggled}
        />
      </div>
    </>
  )
}

export default AdminRightsToggle
