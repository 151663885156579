import { getAuthToken } from 'lib/auth'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { getUserProfileAction } from 'store/actions/user'
import redirectTo from 'utils/redirectTo'
import { bindActionCreators, Dispatch } from 'redux'
import { isEmpty } from 'lodash'
import { ROLES, USER_ROLES } from 'config'

/**
 * UserRoute is used to support the react router and it renders the routes
 * which is marked as private or is only accessible authenticated users
 * @param {React.Component} component
 */
const UserRoute = ({ component, getUserProfileAction, ...rest }) => {
  useEffect(() => {
    ;(async () => {
      //checking if user is already loggedIn in browser then redirect to dashboard and not ask for login
      const jwtToken = await getAuthToken()
      if (jwtToken) {
        if (isEmpty(rest.tenantId)) {
          getUserProfileAction()
        } else {
          redirectTo(
            `/${
              rest.userRole.includes(USER_ROLES.BUSINESS_OWNER)
                ? ROLES.BUSINESS_OWNER
                : rest.userRole.includes(USER_ROLES.BSO_ADMIN)
                ? ROLES.BSO_ADMIN
                : ROLES.BSO_ADVISOR
            }/${rest.tenantId[0]}/dashboard`
          )
        }
      }
    })()
    // eslint-disable-next-line
  }, [rest.tenantId])

  const routeComponent = (props) => React.createElement(component, props)
  return <Route {...rest} render={routeComponent} pageTitle="" />
}
const mapProps = (state: any) => {
  const user = state
  return {
    user,
    tenantId: user.user.user.tenantId,
    userRole: user.user.user.roles,
  }
}
function mapDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserProfileAction: getUserProfileAction.STARTED,
    },
    dispatch
  )
}

export default connect(mapProps, mapDispatch)(UserRoute)
