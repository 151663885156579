import {
  ACTIVESTEP,
  GET_ASSESSMENT_QUESTION,
  GET_USER_ASSESSMENT_RESPONSE,
  GET_USER_GOAL,
  UPDATE_USER_GOAL,
  ADD_EDIT_ASSESSMENT,
  SET_ACTIVE_QUESTION,
  INITIALIZE_OWNER_STEPPER,
  GET_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
  GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_BIG_QUERY,
  GET_SALES_DATA,
  GET_PRODUCT_DATA,
  RESET_ASSESSMENT,
  GET_FINANCE_DATA,
  GET_MARKETING_DATA,
  SAVE_ASSESSMENT_RESPONSE,
  GET_ASSESSMENT_OPTIONS,
  GET_CASH_BALANCE_DATA,
  GET_CASH_BALANCE_BY_MONTH_DATA,
  GET_LIQUID_CASH_DATA,
  GET_PROFIT_AND_LOSS_DATA,
  GET_CONNECTED_PLAY,
} from 'store/types'

const initialState = {
  steps: [],
  activeStep: '',
  questions: [],
  goals: [],
  assessmentResponse: {},
  activeQuestion: {},
  assessmentQuestion: {},
  usersAssessmentResponseByQuestion: {},
  play: {},
  saveAssessmentResponseError: '',
  recommendations: [],
  bigQuery: [],
  salesChartData: [],
  productsData: [],
  isLoadingAssessmentResponse: false,
  marketingData: {},
  isFetchingRecommendations: false,
  assessmentOptions: [],
  accountingData: {
    cashBalanceData: {
      data: [],
      isLoading: false,
    },
    cashBalanceByMonthData: {
      data: [],
      isLoading: false,
    },
    liquidCashData: {
      data: [],
      isLoading: false,
    },
    profitAndLossData: {
      data: [],
      isLoading: false,
    },
  },
  isPlayResponseLoading: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVESTEP:
      return {
        ...state,
        activeStep: action?.payload,
      }
    case RESET_ASSESSMENT:
      return {
        ...state,
        steps: [],
        saveAssessmentResponseError: '',
        activeStep: '',
        questions: [],
        assessmentResponse: {},
        activeQuestion: {},
        assessmentQuestion: {},
        usersAssessmentResponseByQuestion: {},
        isLoadingAssessmentResponse: false,
      }
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED:
      return {
        ...state,
        isFetchingRecommendations: true,
      }
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.REJECTED:
      return {
        ...state,
        isFetchingRecommendations: false,
      }
    case SAVE_ASSESSMENT_RESPONSE.STARTED:
      return {
        ...state,
        saveAssessmentResponseError: '',
        isLoadingAssessmentResponse: true,
      }
    case SAVE_ASSESSMENT_RESPONSE.RESET:
      return {
        ...state,
        saveAssessmentResponseError: '',
      }

    case SAVE_ASSESSMENT_RESPONSE.REJECTED:
      return {
        ...state,
        saveAssessmentResponseError: action.payload,
        isLoadingAssessmentResponse: false,
      }
    case ADD_EDIT_ASSESSMENT.FULLFILLED:
      return {
        ...state,
        questions: action?.payload?.questions,
        assessmentResponse: action?.payload?.assessmentResponse,
      }
    case GET_ASSESSMENT_OPTIONS.FULLFILLED:
      return {
        ...state,
        assessmentOptions: action?.payload,
      }
    case GET_ASSESSMENT_QUESTION.FULLFILLED:
      return {
        ...state,
        questions: action?.payload,
      }
    case UPDATE_USER_GOAL.FULLFILLED:
    case GET_USER_GOAL.FULLFILLED:
      return {
        ...state,
        goals: action.payload,
      }
    case GET_USER_ASSESSMENT_RESPONSE.FULLFILLED:
      return {
        ...state,
        assessmentResponse: action?.payload,
        isLoadingAssessmentResponse: false,
      }
    case SET_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestion: state.questions.find((q: { id: string }) => q.id === action.payload),
      }
    case INITIALIZE_OWNER_STEPPER:
      return {
        ...state,
        steps: action?.payload,
      }
    case GET_CONNECTED_PLAY.STARTED:
      return {
        ...state,
        isPlayResponseLoading: true,
      }
    case GET_CONNECTED_PLAY.FULLFILLED:
      return {
        ...state,
        isPlayResponseLoading: false,
      }
    case GET_CONNECTED_PLAY.REJECTED:
      return {
        ...state,
        isPlayResponseLoading: false,
      }
    case GET_ASSESSMENT_RESPONSE_BY_QUESTION.FULLFILLED:
      return {
        ...state,
        assessmentQuestion: action.payload,
      }
    case GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.FULLFILLED:
      return {
        ...state,
        usersAssessmentResponseByQuestion: action.payload,
      }

    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED:
      return {
        ...state,
        isFetchingRecommendations: false,
        recommendations: action.payload,
      }

    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.RESET:
      return {
        ...state,
        recommendations: [],
      }

    case GET_BIG_QUERY.FULLFILLED:
      let { payload } = action
      return {
        ...state,
        bigQuery: payload,
      }
    case GET_SALES_DATA.FULLFILLED:
      return {
        ...state,
        salesChartData: action.payload,
      }
    case GET_PRODUCT_DATA.FULLFILLED:
      return {
        ...state,
        productsData: action.payload,
      }
    case GET_FINANCE_DATA.FULLFILLED:
      return {
        ...state,
        financeData: action.payload,
      }
    case GET_MARKETING_DATA.FULLFILLED:
      return {
        ...state,
        marketingData: action.payload,
      }
    case GET_CASH_BALANCE_DATA.STARTED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          cashBalanceData: {
            data: {},
            isLoading: true,
          },
        },
      }
    case GET_CASH_BALANCE_BY_MONTH_DATA.STARTED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          cashBalanceByMonthData: {
            data: {},
            isLoading: true,
          },
        },
      }
    case GET_LIQUID_CASH_DATA.STARTED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          liquidCashData: {
            data: {},
            isLoading: true,
          },
        },
      }
    case GET_PROFIT_AND_LOSS_DATA.STARTED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          profitAndLossData: {
            data: {},
            isLoading: true,
          },
        },
      }
    case GET_CASH_BALANCE_DATA.FULLFILLED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          cashBalanceData: {
            data: action.payload,
            isLoading: false,
          },
        },
      }
    case GET_CASH_BALANCE_BY_MONTH_DATA.FULLFILLED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          cashBalanceByMonthData: {
            data: action.payload,
            isLoading: false,
          },
        },
      }
    case GET_LIQUID_CASH_DATA.FULLFILLED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          liquidCashData: {
            data: action.payload,
            isLoading: false,
          },
        },
      }
    case GET_PROFIT_AND_LOSS_DATA.FULLFILLED:
      return {
        ...state,
        accountingData: {
          ...state.accountingData,
          profitAndLossData: {
            data: action.payload,
            isLoading: false,
          },
        },
      }
    default:
  }
  return state
}

export default reducer
