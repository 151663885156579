import React, { useMemo } from 'react'

import { getCalendarEvents } from './FutureEvents.helper'
import {
  CalendarEventViewPreference,
  useGetUserCalendarEventsQuery,
} from '__generated__/api-types-and-hooks'
import { useTenantId } from 'utils/useTenantId'
import UpcomingEvents from 'components/Dashboard/Common/UpcomingEvents/UpcomingEvents.component'

const FutureEvents = ({ calendarEventViewPreference }) => {
  const tenantId = useTenantId()
  const { data, isFetching } = useGetUserCalendarEventsQuery(
    {
      calendarName:
        calendarEventViewPreference === CalendarEventViewPreference.Internal
          ? CalendarEventViewPreference.Internal
          : '',
      tenantId,
    },
    { staleTime: 60000 }
  )
  const { futureMeetings } = getCalendarEvents(data?.getUserCalendarEvents?.data)
  /** These are events parsed into the interface required by the UpcomingEvents component */
  const eventSummaries = useMemo(() => {
    return futureMeetings
      ? futureMeetings.map((event) => ({ description: event.title, date: event.start }))
      : []
  }, [futureMeetings])
  return (
    <UpcomingEvents
      className="lg:order-first"
      noEventsMessage="Get started on your Calendar"
      events={eventSummaries}
      loading={isFetching}
    />
  )
}

export default FutureEvents
