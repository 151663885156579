import React, { FC, useEffect, useState, useMemo } from 'react'

import { DATA_REPORTS_TABS, DataReportsTabs } from 'config'
import { useParams } from 'react-router-dom'

import ButtonTabs from 'components/Common/ButtonTabs'
import GrowingContainer from 'components/Common/GrowingContainer'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { DataContainer, IndustryReportContainer, ReportsContainer } from 'pages/Common/container'
import { getRoleFromPath, navigateTo } from 'utils/helper'
import { ampli } from 'ampli'
import { GetAttestationSummaryInputWithoutTenant } from 'store/actions/attestationSummary'
import {
  ButtonTabsWrapper,
  DataReportsWrapper,
  DataReportsComponentWrapper,
} from 'pages/DataReports/styles'

interface IDataReportsProps {
  userId: string
  getAttestationSummaryAction: (payload: GetAttestationSummaryInputWithoutTenant) => void
}

const DataReports: FC<IDataReportsProps> = ({ userId, getAttestationSummaryAction }) => {
  let { subTab } = useParams()

  const dataReportsEnabled = useFeatureFlag(userId, 'release-attestations-rollout')
  const industryReportEnabled = useFeatureFlag(userId, 'release-industry-report')

  useEffect(() => {
    if (dataReportsEnabled) {
      const today = new Date()
      getAttestationSummaryAction({
        timePeriod: {
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          day: today.getDate(),
        },
        advisorId: userId,
        includeAggregationDetails: true,
      })
    }
    // eslint-disable-next-line
  }, [dataReportsEnabled])

  const [selectedTab, setSelectedTab] = useState(DataReportsTabs[0])

  const userRole = getRoleFromPath()
  useEffect(() => {
    let foundTab = DataReportsTabs.find((tab) => tab.link === subTab)
    if (foundTab) {
      ampli.subtabViewed({ subTabName: foundTab.label })
      setSelectedTab(foundTab)
    } else {
      navigateTo(userRole, '')
    }
  }, [subTab, userRole])

  const onHandleTabChange = (value) => {
    setSelectedTab(value)
    let foundTab = DataReportsTabs.find((tab) => tab.label === value.label)
    if (foundTab) {
      ampli.subtabViewed({ subTabName: foundTab.label })
      navigateTo(userRole, `data-reports/${foundTab?.link}`)
    } else {
      navigateTo(userRole, `data-reports/${value?.label}`)
    }
  }

  useMemo(
    () =>
      DataReportsTabs.forEach((tab) =>
        tab.label === DATA_REPORTS_TABS.INDUSTRY_REPORT
          ? (tab.isVisible = industryReportEnabled)
          : { ...tab }
      ),
    [industryReportEnabled]
  )

  return (
    <>
      <GrowingContainer id="data-and-reports">
        <DataReportsWrapper>
          <ButtonTabsWrapper id="tab-container">
            <ButtonTabs
              options={DataReportsTabs.filter((tab) => tab.isVisible)}
              setSelectedTab={onHandleTabChange}
              selectedTab={selectedTab}
            />
          </ButtonTabsWrapper>
          <DataReportsComponentWrapper>
            {selectedTab.label === DATA_REPORTS_TABS.DATA && <DataContainer />}
            {selectedTab.label === DATA_REPORTS_TABS.REPORTS && (
              <ReportsContainer reportsEnabled={dataReportsEnabled} />
            )}
            {selectedTab.label === DATA_REPORTS_TABS.INDUSTRY_REPORT && industryReportEnabled && (
              <IndustryReportContainer />
            )}
          </DataReportsComponentWrapper>
        </DataReportsWrapper>
      </GrowingContainer>
    </>
  )
}

export default DataReports
