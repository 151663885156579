import React, { useState, useEffect, useMemo } from 'react'

import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import first from 'lodash/first'
import find from 'lodash/find'
import includes from 'lodash/includes'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { isMobileOnly } from 'mobile-device-detect'
import {
  copy,
  findValueFromResponse,
  getAnswer,
  getReturnToPath,
  getRoleFromPath,
  isFutureDate,
  isMultiFieldDateInFuture,
  navigateTo,
  scrollToTop,
} from 'utils/helper'
import { createAssessmentRespnse } from 'utils/assessments'
import {
  registerQuestionType,
  getNumericInputOption,
  checkMultiInputValue,
  getNextQuestion,
  IOptionTypes,
  getButtonLabel,
} from 'components/Assessment/AssessmentQuestion/utils'

import { ASSESSMENT_TYPE, FIND_VALUE, LABEL, MEDIA_BUCKET, ROLES } from 'config'
import { ASSESSMENTS, skipRecommendationForAssessmentTypes } from 'config/data'
import { cloneDeep, isArray, map } from 'lodash'
import { ampli } from 'ampli'
import { checkError, getErrorMessage } from 'utils/helper'

import Button from 'components/Common/Button'
import { ButtonWrapper, CheckboxWrapper } from 'components/Assessment/AssessmentQuestion/styles'
import CardSelect from 'components/Common/CardSelect'
import CheckBox from 'components/Common/CheckBox'
import Description from 'components/Assessment/AssessmentQuestion/Description'
import Helper from 'components/Assessment/AssessmentQuestion/Helper'
import Info from 'components/Assessment/AssessmentQuestion/Info'
import InputField from 'components/Assessment/AssessmentQuestion/InputField'
import CurrencyInput from 'components/Common/CurrencyField'
import UploadSuccessScreen from 'components/Assessment/AssessmentQuestion/UploadSuccessScreen'
import NumberField from 'components/Assessment/AssessmentQuestion/NumberField'
import Loaders from 'components/Loaders'
import SelectInfo from 'components/Assessment/AssessmentQuestion/SelectInfo'
import SkipButton from 'components/Assessment/AssessmentQuestion/SkipButton'
import Title from 'components/Assessment/AssessmentQuestion/Title'
import TileSelect from 'components/Common/TileSelect'
import Upload from 'components/Assessment/Upload'
import MultiFieldComponent from 'components/Assessment/AssessmentQuestion/MultiFieldComponent'
import { AssessmentRecommendationsContainer } from 'pages/Assessment/containers'
import AssessmentImage from 'components/Assessment/AssessmentQuestion/AssessmentImage'
import ErrorMessage from 'components/Common/ErrorMessage'

const AssessmentQuestion = ({
  user,
  userId,
  saveAssessmentResponseError,
  assessmentOptions,
  isPlay,
  disable,
  widget,
  onSubmit,
  hideButton,
  playQuestion,
  uploadedFile,
  activeQuestion,
  assessmentType,
  businessProfile,
  recommendations,
  uploadFileAction,
  setActiveQuestion,
  uploadFileLoading,
  assessmentResponse,
  isCompletedAssessment,
  handleBackTogggle = '',
  isOverRideBack = false,
  updateProgressbarAction = () => {},
  isLoadingAssessmentResponse,
  previousQuestionAction,
  assessmentBgColorAction,
  saveAssessmentResponseAction,
  inputFieldbgColorAction = (e) => {},
  moveBackOnFirstQuestion = () => {},
  resetUploadFileAction,
  savePlayData,
  isSupportedPlays,
  selectedOptions,
  playData,
  resetSaveAssessmentResponseError,
  resetAssessment,
}) => {
  const [isSubmitted, setIsSubmitted]: any = useState(false)
  const [selectedOption, setSelectedOption]: any = useState(null)
  const [options, setOptions]: any = useState([]) //for play (checkboxes)
  const [multiFieldValue, setMultiFieldValue]: any = useState([])
  const regex: any = activeQuestion?.meta?.regex
    ? new RegExp(activeQuestion?.meta?.regex)
    : undefined
  const mask = activeQuestion?.meta?.mask ? activeQuestion?.meta?.mask : undefined
  const selectInfo = get(activeQuestion, 'meta.selectInfo', undefined)
  const allowMultiSelect = get(activeQuestion, 'meta.allowMultiSelect', false)
  const businessName = get(businessProfile, `businessName`, 'your business') || 'your business'
  const prefix = '$' // I am setting prefix from here this will make  CurrencyField more generic
  let queryParam = getReturnToPath()
  const redirectPath = queryParam?.returnTo || 'dashboard'

  const questionType = useMemo(() => {
    return registerQuestionType(activeQuestion?.optionType)
  }, [activeQuestion])
  const isNextHidden = useMemo(() => {
    if (!isCompletedAssessment) return false
    let selectedAnswer: any = find(activeQuestion?.options, (op) => includes(selectedOption, op.id))
    if (size(activeQuestion?.options) === 1) selectedAnswer = first(activeQuestion?.options)
    const next = selectedAnswer?.action?.next?.default
    return !next
  }, [isCompletedAssessment, selectedOption, activeQuestion])

  useEffect(() => {
    if (isSupportedPlays) {
      setOptions(selectedOptions)
    }
  }, [isSupportedPlays, selectedOptions])

  useEffect(() => {
    return () => {
      if (assessmentType !== ASSESSMENT_TYPE.INITIAL) {
        resetAssessment()
      }
    }
    // eslint-disable-next-line
  }, [])
  const moveBack = () => {
    resetSaveAssessmentResponseError()
    let { previous } = activeQuestion
    if (previous && previous.length > 0) {
      let payload = {
        previous,
        activeQuestionId: activeQuestion?.id,
      }
      previousQuestionAction(payload)
    } else {
      moveBackOnFirstQuestion()
    }
  }

  useEffect(() => {
    if (activeQuestion?.meta?.bgColor) {
      assessmentBgColorAction(activeQuestion?.meta?.bgColor)
      inputFieldbgColorAction('bg-white')
    } else {
      assessmentBgColorAction('bg-white')
      inputFieldbgColorAction('bg-grey-light')
    }
    // eslint-disable-next-line
  }, [activeQuestion?.meta?.bgColor])

  useEffect(() => {
    if (isOverRideBack && handleBackTogggle !== '') {
      moveBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBackTogggle, isOverRideBack])

  useEffect(() => {
    if (!isEmpty(activeQuestion) && !isPlay) {
      ampli.assessmentQuestionViewed({
        id: assessmentType,
        name: ASSESSMENTS[assessmentType]?.title,
        question:
          get(activeQuestion, `content.${getRoleFromPath()!}.title`) ||
          get(activeQuestion, `content.${getRoleFromPath()!}.description`),
        questionId: activeQuestion?.id,
      })
    }
  }, [assessmentType, activeQuestion, isPlay])

  useEffect(() => {
    if (!isEmpty(activeQuestion) && !isEmpty(assessmentResponse)) {
      const response = assessmentResponse.assessmentResponse.find((d) => d.id === activeQuestion.id)
      if (response) {
        setSelectedOption(response.value)
        if (questionType?.isMultiField) {
          setMultiFieldValue(response.value)
        }
      } else {
        setSelectedOption('')
        setMultiFieldValue([])
      }
    }
  }, [activeQuestion, assessmentResponse, questionType.isMultiField])

  const handleForceSkip = (answer) => {
    answer = copy(answer)
    if (!answer.id && isCompletedAssessment) skip()
  }

  const moveToNext = (selected_options, navigationId = '') => {
    setIsSubmitted(true)
    let id
    activeQuestion = copy(activeQuestion)
    let answer: any = find(activeQuestion?.options, (op) => includes(selectedOption, op.id))
    if (answer) {
      answer.label = answer.value
    }

    if (allowMultiSelect) {
      answer = filter(activeQuestion?.options, (op) => includes(selectedOption, op.id))
    }

    if (questionType.isButtonOrSuccessDescription) {
      answer = activeQuestion?.options[0]
    }

    if (questionType.isInput || questionType.isCurrency || questionType.isUpload) {
      answer = cloneDeep(activeQuestion?.options[0])
      answer.id = selectedOption
      answer.label = selectedOption
      handleForceSkip(answer)
    }
    if (questionType.isNumeric || questionType.isCurrency) {
      answer = cloneDeep(getNumericInputOption(activeQuestion.options, selectedOption))
      answer.id = selectedOption
      answer.label = selectedOption
      handleForceSkip(answer)
    }

    if (questionType.isPlayCheckbox) {
      answer = activeQuestion?.options[0]
      answer.id = selected_options
      answer.label = selected_options
    }

    if (questionType?.isMultiField) {
      answer = cloneDeep(activeQuestion?.options[0])
      id = answer.id
      answer.id = multiFieldValue
      answer.label = multiFieldValue
    }

    const matchedData = allowMultiSelect ? first(answer) : answer

    if (
      questionType?.isMultiField &&
      (multiFieldValue?.length === activeQuestion?.options?.length
        ? multiFieldValue.some((item) => isEmpty(item.value))
        : true)
    )
      return
    if (matchedData?.id && ((regex && matchedData?.id?.toString().match(regex)) || !regex)) {
      handleSaveAssessmentResponse(answer, id, navigationId)
      setIsSubmitted(false)
      if (
        isCompletedAssessment &&
        navigationId &&
        activeQuestion?.options &&
        !activeQuestion?.options[0]?.action?.next?.default
      )
        navigateTo(ROLES.BUSINESS_OWNER, navigationId)
    }
  }

  const handleSaveAssessmentResponse = (answers, id, navigationId = '') => {
    answers = copy(answers)
    const next = getNextQuestion(allowMultiSelect, answers)

    if (!isPlay && !isCompletedAssessment) {
      ampli.assessmentQuestionAnswered({
        id: assessmentType,
        name: ASSESSMENTS[assessmentType]?.title || 'Unknown Assessment',
        question:
          activeQuestion.content.owner?.title ||
          activeQuestion.content.owner?.description ||
          'No question title',
        answers: getAnswer(questionType, answers, allowMultiSelect),
        answerIds: allowMultiSelect
          ? map(answers, 'id')
          : isArray(answers.id)
          ? map(answers.id, 'id')
          : questionType.isInput || questionType.isNumeric || questionType.isCurrency
          ? []
          : [answers.id],
        questionId: activeQuestion?.id,
      })
    }

    if (next) {
      setActiveQuestion(next)
      scrollToTop()
    } else {
      setActiveQuestion(null)
      if (!isPlay && !isCompletedAssessment) {
        ampli.assessmentFinished({
          id: assessmentType,
          name: ASSESSMENTS[assessmentType]?.title || 'Unknown Assessment',
          userName: user.firstName + ' ' + user.lastName,
          businessName: businessName,
          industry: findValueFromResponse(
            assessmentResponse,
            assessmentOptions,
            FIND_VALUE.BUSINESS_CLASSIFICATION,
            businessProfile?.businessClassification
          ),
          stage: findValueFromResponse(
            assessmentResponse,
            assessmentOptions,
            FIND_VALUE.BUSINESS_STAGE,
            businessProfile?.businessStage
          ),
          recommendedPlays: recommendations?.map((data) => data.description),
        })
      }
      onSubmit()
    }

    let assessmentResponseObj = {
      activeQuestion: activeQuestion.id,
      navigationId: navigationId,
      widget,
      isLastQuestion: isPlay ? !isPlay : !next,
      userId,
      type: assessmentType,
      currentStage: next === '' ? activeQuestion?.id : next, // Selected option next for next time open page
      assessmentResponse: createAssessmentRespnse(activeQuestion, answers, allowMultiSelect, next),
    }
    if (isSupportedPlays) savePlayData({ selectedOption: copy(answers?.id), ...playData })
    if (!isCompletedAssessment && !isSupportedPlays)
      saveAssessmentResponseAction(assessmentResponseObj)
    setSelectedOption(null)
    if (questionType?.isMultiField) {
      activeQuestion.options[0].id = id
    }
    setMultiFieldValue([])
    updateProgressbarAction()
  }

  const skip = () => {
    let options = activeQuestion?.options[0]
    options.id = ''
    options.label = ''
    handleSaveAssessmentResponse(options, options.id)
  }
  useEffect(() => {
    if (assessmentResponse && questionType.isPlayCheckbox) {
      // find answers
      let currentQuestionAnswers: any = []
      let answers = []
      answers = assessmentResponse?.assessmentResponse
      // find answers of current question
      if (answers.length > 0) {
        answers.forEach((obj: any) => {
          if (obj?.id === activeQuestion?.id) {
            currentQuestionAnswers = [...JSON.parse(obj.value)]
          }
        })
      }
      if (currentQuestionAnswers.length > 0) {
        setOptions(currentQuestionAnswers)
      }
    }
  }, [activeQuestion?.id, assessmentResponse, questionType])

  const handleChange = (value) => {
    const noneOfTheseOption = activeQuestion?.options?.find(
      (option) => option?.isNoneOfThese === true
    )
    let answer = value
    if (!isCompletedAssessment) {
      if (allowMultiSelect) {
        if (value === noneOfTheseOption?.id) {
          answer = selectedOption.includes(value) ? [] : [value]
        } else if (
          selectedOption?.includes(noneOfTheseOption?.id) ||
          includes(selectedOption, value)
        ) {
          answer = filter(selectedOption, (option) => option !== value)
        } else {
          answer = selectedOption ? [...selectedOption, answer] : [answer]
        }
      }
      setSelectedOption(answer)
    }
  }
  const getErrors = (multiFieldValue, field) => {
    if (field.label === LABEL.MONTH) {
      const monthValue = multiFieldValue.find((obj) => obj.label === LABEL.MONTH)?.value
      const yearValue = multiFieldValue.find((obj) => obj.label === LABEL.YEAR)?.value

      return isFutureDate(monthValue, yearValue)
    } else {
      return null
    }
  }

  if (isLoadingAssessmentResponse || isEmpty(activeQuestion))
    return (
      <div className="xs:w-[310px] sm:w-[510px] px-5">
        <Loaders loader="AssessmentLoader" />
      </div>
    )
  return (
    <>
      {saveAssessmentResponseError && (
        <ErrorMessage id={'assessment-error'}>{saveAssessmentResponseError}</ErrorMessage>
      )}
      {activeQuestion?.optionType !== 'successDescription' ? (
        <>
          {(!questionType?.isUpload || (questionType?.isUpload && !selectedOption)) && (
            <div
              className={
                questionType.isQuestion ? 'mt-24 mb-[68px] font-normal text-primary-text' : ''
              }
            >
              {activeQuestion?.content?.owner?.image && (
                <div id="content-owner-image-wrapper" className="flex justify-center mt-2">
                  <img
                    id="content-owner-img"
                    src={`${MEDIA_BUCKET}/${activeQuestion?.content?.owner?.image}`}
                    alt="PlayIcon"
                  />
                </div>
              )}
              <Title
                id="title-content"
                user={user}
                marginTop={get(activeQuestion, 'meta.headerMargin', '')}
                text={get(activeQuestion, `content.${getRoleFromPath()!}.title`)}
                businessName={businessName}
              />
              {activeQuestion?.meta?.image && (
                <div id="active-question-meta-image" className="flex justify-center mt-2">
                  <img src={`${MEDIA_BUCKET}/${activeQuestion?.meta?.image}`} alt="PlayIcon" />
                </div>
              )}
              <Info
                id="content-owner-info"
                user={user}
                text={get(activeQuestion, 'content.owner.info')}
                businessName={businessName}
              />
              <Description
                user={user}
                text={get(activeQuestion, `content.${getRoleFromPath()!}.description`)}
                businessName={businessName}
                playQuestion={playQuestion}
              />
            </div>
          )}
          {activeQuestion?.optionType !== 'successDescription' &&
            (activeQuestion?.optionType === IOptionTypes.CHECKBOX ||
              activeQuestion?.optionType === IOptionTypes.SELECT) && (
              <SelectInfo allowMultiSelect={allowMultiSelect} selectInfo={selectInfo} />
            )}
          <div
            className={`${
              isMobileOnly || questionType.isPlayCheckbox
                ? 'xs:px-0 pt-2 sm:px-5'
                : 'sm:px-0 pt-2 md:px-0'
            }`}
          >
            {questionType.isPlayCheckbox && (
              <div id="play-checkbox-wrapper" className="flex flex-col gap-y-1">
                {activeQuestion?.options?.map((option, index) => {
                  return (
                    <CheckBox
                      disable={disable}
                      key={index}
                      option={option}
                      options={options}
                      setOptions={setOptions}
                      moveToNext={moveToNext}
                    />
                  )
                })}
              </div>
            )}

            {questionType.isCheckbox && (
              <CheckboxWrapper id="checkbox-wrapper">
                <TileSelect
                  id="tile-select"
                  value={selectedOption}
                  onChange={handleChange}
                  options={activeQuestion.options.map((opt) => ({
                    label: opt.value,
                    value: opt.id,
                    icon: opt.icon,
                    description: opt.description,
                    title: opt.title,
                  }))}
                  isMulti={allowMultiSelect}
                />
              </CheckboxWrapper>
            )}

            {questionType.isNumeric && (
              <NumberField
                isSubmitted={isSubmitted}
                mask={mask}
                label={
                  activeQuestion.options[activeQuestion.options.length - 1]?.label
                    ? activeQuestion.options[activeQuestion.options.length - 1]?.label
                    : activeQuestion?.meta?.label
                }
                isCompletedAssessment={isCompletedAssessment}
                handleChange={handleChange}
                selectedOption={selectedOption}
                infoText={activeQuestion.options[activeQuestion.options.length - 1]?.infoText}
              />
            )}

            {questionType.isInput && (
              <InputField
                isSubmitted={isSubmitted}
                mask={mask}
                label={
                  activeQuestion.options[activeQuestion.options.length - 1]?.label
                    ? activeQuestion.options[activeQuestion.options.length - 1]?.label
                    : activeQuestion?.meta?.label
                }
                regex={regex}
                isCompletedAssessment={isCompletedAssessment}
                selectedOption={selectedOption}
                handleChange={handleChange}
                errorMessage={get(activeQuestion, 'options[0].meta.errorMessage', '')}
                placeholder={get(activeQuestion, 'options[0].meta.placeholder', '')}
                infoText={activeQuestion.options[activeQuestion.options.length - 1]?.infoText}
              />
            )}
            {questionType.isCurrency && (
              <div id="currency-input-wrapper" className="sm:max-w-[510px]">
                <CurrencyInput
                  disabled={isCompletedAssessment}
                  label={
                    activeQuestion.options[activeQuestion.options.length - 1]?.label
                      ? activeQuestion.options[activeQuestion.options.length - 1]?.label
                      : activeQuestion?.meta?.label
                  }
                  className="text-left"
                  prefix={prefix}
                  onValueChange={(value) => handleChange(value || 0)}
                  value={selectedOption ? selectedOption : ''}
                  errors={
                    checkError(regex, selectedOption, isSubmitted)
                      ? { message: getErrorMessage(activeQuestion?.meta?.label) }
                      : ''
                  }
                  infoText={activeQuestion.options[activeQuestion.options.length - 1]?.infoText}
                />
              </div>
            )}

            {questionType.isSelect && (
              <div className="mt-0">
                <CardSelect
                  value={selectedOption}
                  onChange={handleChange}
                  options={activeQuestion.options.map((opt) => ({
                    label: opt.value,
                    value: opt.id,
                    icon: opt.icon,
                  }))}
                  isMulti={allowMultiSelect}
                />
              </div>
            )}
            {questionType.isMultiField && (
              <div id="multifield-input-wrapper" className="mt-0">
                <MultiFieldComponent
                  isSubmitted={isSubmitted}
                  selectedOption={selectedOption}
                  activeQuestion={activeQuestion}
                  multiFieldValue={multiFieldValue}
                  setMultiFieldValue={setMultiFieldValue}
                  isCompletedAssessment={isCompletedAssessment}
                  errors={getErrors}
                />
              </div>
            )}
          </div>

          {questionType.isSuccessDescription ? (
            <div className="mt-5 w-[300px] md:w-[400px]">
              <Button
                variant="primary"
                type="button"
                label="Continue"
                onClick={moveToNext}
                className={`text-sm w-full ${isNextHidden && 'hidden'}`}
              />
            </div>
          ) : questionType.isButton && !hideButton && !activeQuestion.meta.hideButton ? (
            <ButtonWrapper isMobileOnly={isMobileOnly}>
              <Button
                className="!text-2xl w-full"
                variant="primary"
                type="button"
                label={getButtonLabel(activeQuestion, questionType)}
                onClick={moveToNext}
                disabled={disable || (questionType.isInput && isCompletedAssessment)}
              />
            </ButtonWrapper>
          ) : null}

          {questionType.isUpload && (
            <>
              {!isEmpty(selectedOption) ? (
                <UploadSuccessScreen
                  uploadType={activeQuestion?.options[0]?.uploadType}
                  selectedOption={selectedOption}
                />
              ) : (
                <div>
                  <div id="upload-wrapper" className="mt-5 w-full">
                    {questionType.isUpload && (
                      <Upload
                        userId={userId}
                        uploadedFile={uploadedFile}
                        activeQuestion={activeQuestion}
                        setSelectedOption={handleChange}
                        uploadFileAction={uploadFileAction}
                        uploadFileLoading={uploadFileLoading}
                        isCompletedAssessment={isCompletedAssessment}
                        uploadType={activeQuestion?.options[0]?.uploadType}
                        isMultiple={false}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <Helper
            className={questionType.isButton ? 'text-center' : 'text-left'}
            text={get(activeQuestion, `content.${getRoleFromPath()!}.helper`)}
          />
        </>
      ) : (
        <>
          <div
            id="assesssment-question-div"
            className="flex text-center items-center justify-center flex-col"
          >
            <div className="w-[100%]">
              <div className="flex text-center items-center justify-center"></div>
              {!isEmpty(activeQuestion?.content?.[getRoleFromPath()!]?.image) && (
                <AssessmentImage
                  imageUrl={`${MEDIA_BUCKET}/${
                    activeQuestion?.content?.[getRoleFromPath()!]?.image
                  }`}
                />
              )}
              <div>
                <Title
                  user={user}
                  text={get(activeQuestion, `content.${getRoleFromPath()!}.title`)}
                  businessName={businessName}
                />
              </div>
              {!isEmpty(activeQuestion?.content?.[getRoleFromPath()!]?.postTitleImage) && (
                <AssessmentImage
                  imageUrl={`${MEDIA_BUCKET}/${
                    activeQuestion?.content?.[getRoleFromPath()!]?.postTitleImage
                  }`}
                  margin="my-6"
                />
              )}
              {activeQuestion?.content?.[getRoleFromPath()!]?.isImage &&
                activeQuestion?.content?.[getRoleFromPath()!]?.image === undefined && (
                  <div className=" flex justify-center">
                    <div className="bg-primary rounded-full h-[200px] w-[200px] fill-white" />
                  </div>
                )}

              <br />
              {!isCompletedAssessment &&
              activeQuestion?.options &&
              !skipRecommendationForAssessmentTypes.includes(assessmentType) &&
              !assessmentType.includes('sales') &&
              !activeQuestion?.options[0]?.action?.next?.default ? (
                <AssessmentRecommendationsContainer
                  assessmentType={assessmentType}
                  moveToNext={moveToNext}
                  description={activeQuestion?.content?.[getRoleFromPath()!]?.description}
                />
              ) : (
                <div>
                  <section
                    id="active-question-description"
                    className="font-primary text-xl whitespace-pre-line text-left"
                  >
                    {activeQuestion?.content?.[getRoleFromPath()!]?.description}
                  </section>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {!playQuestion && !questionType.isButton && (
        <div id="non-button-assessment-question" className="w-[100%]">
          <div className="mt-8 flex justify-between items-center gap-x-2">
            <div className="w-[122px] cursor-pointer inline-block  h-16">
              <Button
                id="button-back"
                className="w-full !text-2xl !h-16"
                type="button"
                label="Back"
                variant="secondary"
                onClick={moveBack}
              />
            </div>
            <div className="w-full max-w-[200px] cursor-pointer flex items-center justify-center text-sm h-16">
              {/* check-button -- clean up custom styles */}
              {(questionType.isUpload ? selectedOption?.name : true) && (
                <Button
                  id="button-next"
                  className="!text-2xl !h-16 w-full"
                  variant="primary"
                  type="button"
                  label={
                    activeQuestion?.options && !activeQuestion?.options[0]?.action?.next?.default
                      ? 'Done'
                      : 'Next'
                  }
                  onClick={() => {
                    //reset state here on success screen
                    questionType.isUpload && selectedOption && resetUploadFileAction()
                    moveToNext(null, assessmentType === 'initial' ? '' : `${redirectPath}`)
                  }}
                  disabled={
                    isLoadingAssessmentResponse ||
                    isMultiFieldDateInFuture(multiFieldValue, activeQuestion.optionType) ||
                    ((selectedOption === null || selectedOption === '') &&
                      multiFieldValue.every((field) => isEmpty(field.value)) &&
                      activeQuestion.optionType !== 'successDescription')
                  }
                />
              )}

              {activeQuestion?.meta?.skip &&
                questionType.isUpload &&
                !selectedOption?.name &&
                !selectedOption && (
                  <SkipButton
                    label={activeQuestion?.meta?.skip}
                    isHidden={false}
                    handleClick={skip}
                    margin="mt-0"
                  />
                )}
            </div>
          </div>
          {activeQuestion?.meta?.skip &&
            !questionType.isUpload &&
            (selectedOption ? !selectedOption : checkMultiInputValue(multiFieldValue)) && (
              <SkipButton
                label={activeQuestion?.meta?.skip}
                isHidden={questionType.isUploadOrInput && isCompletedAssessment}
                handleClick={skip}
              />
            )}
        </div>
      )}
    </>
  )
}

AssessmentQuestion.defaultProps = {
  user: {},
  assessmentOptions: {},
  hideButton: false,
  businessProfile: {},
  isCompletedAssessment: false,
  isLoadingAssessmentResponse: false,
  isLastQuestion: false,
  widget: false,
  assessmentBgColorAction: () => {},
  setIsLastQuestion: (isLastQuestion: boolean) => {},
  playQuestion: false,
  disable: false,
  previousQuestionAction: (obj) => {},
  isPlay: false,
  uploadFileLoading: false,
  resetImageUrlAction: () => {},
  recommendations: [],
  getRecommendedPlaysAssessmentsAction: () => {},
  uploadedFile: {},
  resetUploadFileAction: {},
  savePlayData: () => {},
  isSupportedPlays: false,
  selectedOptions: [],
  playData: {},
  resetSaveAssessmentResponseError: () => {},
  saveAssessmentResponseError: '',
  resetAssessment: () => {},
}

export default AssessmentQuestion
