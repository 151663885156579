import { createSelector } from 'reselect'
import { cloneDeep, get, size } from 'lodash'
import moment from 'moment'

import { Tool, ToolConnectionStatus } from '__generated__/api-types-and-hooks'
import { CALENDARS_LIST, CYCLR_CALENDAR, toolsTabs } from 'config'
import {
  copy,
  dateFormatterHelper,
  getCurrentRoute,
  getReturnToPath,
  getToolFlyoutOptions,
  groupToolsByCategoryHelper,
  sortHandlerHelper,
} from 'utils/helper'
import { ExtendedTools } from 'types'

export const getClientsData = (state) => state.clients?.clientsData
export const getScoreCards = (state) => state.clients?.client?.activity?.scoreCards
export const getActivityLogs = (state) => state.clients?.client?.activity?.activityLogs
export const getBusinessProfile = (state) => state.clients?.client?.businessProfile
export const getAssessmentResponse = (state) => state.clients?.client?.assessmentResponse
const getGoalSelector = (state) => state.owner.goals
export const getAssessmentQuestion = (state) => state.owner.questions
export const getToolsData = (state) => state.clients?.myTools || []
const getTackleMeetingEvents = (state) => state.clients
const getCurrentUser = (state) => state.user.user
const getClientsComment = (state) => state.clients
const getAllToolsData = (state) => state.clients?.allTools

export const getClientsActivity = createSelector([getClientsData], (clientsData) => {
  let clientData: any = []
  clientData.push({
    title: '# Accounts Created',
    count: clientsData?.accounts?.length || 0,
    icon: 'Accounts',
  })
  clientData.push({ title: '# Sign-ins', count: clientsData?.signIns || 0, icon: 'Signin' })
  clientData.push({
    title: '# Meetings Scheduled',
    count: clientsData?.meetingScheduled || 0,
    icon: 'MeetingSchedule',
  })
  clientData.push({
    title: '# Meetings Re-Scheduled',
    count: clientsData?.meetingReScheduled || 0,
    icon: 'MeetingReschedule',
  })
  return clientData
})

export const getClientActivityScoreCards = createSelector([getScoreCards], (scoreCard) => {
  try {
    let scoreCardsArr: any = []

    scoreCardsArr.push({
      header: '# times signed in',
      value: scoreCard?.timesSignedIn ? scoreCard.timesSignedIn : 0,
      avg: scoreCard?.signInPerWeek ? `${parseInt(scoreCard?.signInPerWeek)} times per week` : '',
      textSize: 'xs:text-xl sm:text-6xl',
    })

    scoreCardsArr.push({
      header: '# chats',
      value: scoreCard.chats ? scoreCard.chats : 0,
      avg: '',
      textSize: 'xs:text-xl sm:text-6xl',
    })

    // TODO: Comment out for now until this has been fixed.
    // scoreCardsArr.push({
    //   header: 'time spent on platform',
    //   value: scoreCard.timeOnPlatform ? scoreCard.timeOnPlatform : 0,
    //   avg: scoreCard?.avgMinsPerSession
    //     ? `${parseInt(scoreCard?.avgMinsPerSession)} minutes per session`
    //     : '',
    //   textSize: 'xs:text-sm sm:text-5xl',
    // })

    scoreCardsArr.push({
      header: '# meetings scheduled',
      value: scoreCard.meetingsScheduled ? scoreCard.meetingsScheduled : 0,
      avg: '',
      textSize: 'xs:text-xl sm:text-6xl',
    })

    scoreCardsArr.push({
      header: 'tech tools integrated',
      value: scoreCard.toolsIntegrated ? scoreCard.toolsIntegrated : 0,
      avg: '',
      textSize: 'xs:text-xl sm:text-6xl',
    })

    scoreCardsArr.push({
      header: '# meetings re-scheduled',
      value: scoreCard.meetingsReScheduled ? scoreCard.meetingsReScheduled : 0,
      avg: '',
      textSize: 'xs:text-xl sm:text-6xl',
    })

    return scoreCardsArr
  } catch (err) {
    console.log('error in selector (getClientActivityScoreCards)')
    return []
  }
})

export const getClientActivityLogs = createSelector([getActivityLogs], (activityLogs) => {
  try {
    const activityLogsData = {}

    activityLogs = sortHandlerHelper({
      sortField: 'createdAt',
      sortOrder: 'DSC',
      fieldType: 'date',
      data: activityLogs,
    })

    activityLogs?.forEach((activity) => {
      if (activity?.showClientActivity) {
        const month = activity?.createdAt
          ? moment(activity?.createdAt).format('MMMM').toUpperCase()
          : null
        if (month) {
          if (moment().format()) if (!activityLogsData[month]) activityLogsData[month] = []
          activity.createdAt = dateFormatterHelper(activity.createdAt)
          activityLogsData[month].push(activity)
        }
      }
    })

    for (let key in activityLogsData) {
      let sortedArr = sortHandlerHelper({
        sortField: 'updatedAt',
        sortOrder: 'DSC',
        fieldType: 'date',
        data: activityLogsData[key],
      })

      activityLogsData[key] = sortedArr
    }

    return activityLogsData
  } catch (err) {
    console.log(err)
    return {}
  }
})

export const getClientBusinessDetails = createSelector(
  [getBusinessProfile, getGoalSelector, getAssessmentResponse],
  (businessProfile, goals) => {
    if ((businessProfile.length && goals.length) === 0) return

    let otherGoals = []
    try {
      if (businessProfile.otherGoals) {
        const parseGoal = JSON.parse(get(businessProfile, 'otherGoals', '{}'))
        if (parseGoal?.length) {
          otherGoals = goals?.filter((goal) => {
            return parseGoal.find((element) => {
              return element === goal.id
            })
          })
        }
      }

      businessProfile.otherGoals = otherGoals
      return businessProfile
    } catch (err) {
      console.log('err: ', err)
      return {}
    }
  }
)

export const getGoalsSelector = createSelector([getGoalSelector], (goals) => {
  try {
    if (goals && goals.length) {
      let userGoals = goals.sort(function (a, b) {
        return Number(a?.orderOfSequence) - Number(b?.orderOfSequence)
      })

      userGoals = goals.map((goal) => ({
        label: goal.name,
        value: goal.id || '',
      }))
      return userGoals
    }
    return
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})

export const getOrderedAssessmentsSelector = createSelector(
  [getAssessmentQuestion],
  (assessment) => {
    const lastItem = assessment.pop()
    assessment.unshift(lastItem)
    let assessmentData = assessment.filter((question) => question?.meta?.showToBusinessProfile)
    assessmentData = assessmentData.sort(function (a, b) {
      return a?.meta?.advisorEditOwnerSequence - b?.meta?.advisorEditOwnerSequence
    })
    return assessmentData
  }
)

export const getClientDetailsAssessmentSelector = createSelector(
  [getAssessmentQuestion],
  (assessment) => {
    const lastItem = assessment.pop()
    assessment.unshift(lastItem)
    let assessmentData = assessment.filter((question) => question?.meta?.showToClientDetail)
    assessmentData = assessmentData.sort(function (a, b) {
      return a?.meta?.advisorEditOwnerSequence - b?.meta?.advisorEditOwnerSequence
    })
    return assessmentData
  }
)

export const getOtherDetailsListSelector = createSelector([getAssessmentQuestion], (assessment) => {
  const lastItem = assessment.pop()
  assessment.unshift(lastItem)
  let assessmentData = assessment.filter((question) => question?.meta?.showToClientOtherDetails)
  assessmentData = assessmentData.sort(function (a, b) {
    return a?.meta?.clientOrderSequence - b?.meta?.clientOrderSequence
  })
  return assessmentData
})

export const getCompletedToolsCountSelector = createSelector([getToolsData], (myTools) => {
  let count = myTools?.filter((tool) => tool.isConnected)
  return size(count)
})
export const getTackleMeetingSelector = createSelector(
  [getTackleMeetingEvents, getCurrentUser],
  (clients, user) => {
    const tackleEvents: any = []
    let date = new Date()
    let todayDate = date.getDate()
    let isAttendee
    let event = copy(clients?.tackleMeeting?.data)
    try {
      for (let i = 0; i < event?.length; i++) {
        let time
        let eventDate
        const eventStartTime = event[i].start.dateTime
        const eventEndTime = event[i]?.end.dateTime
        let attendee = event[i]?.profile
        attendee = JSON.parse(attendee)

        time = `${moment(eventStartTime).format('hh:mm A')} - ${moment(eventEndTime).format(
          'hh:mm A'
        )}`

        if (eventStartTime && clients?.tackleMeeting?.filter.includes('upcoming')) {
          let dateObj = new Date(eventStartTime)
          if (dateObj.getDate() === todayDate) {
            eventDate = 'Today'
          } else if (dateObj.getDate() === todayDate + 1) {
            eventDate = 'Tomorrow'
          } else {
            eventDate = moment(eventStartTime).format('MMM D, y')
          }
        } else {
          eventDate = moment(eventStartTime).format('MMM D, y')
        }

        if (typeof event[i].attendees === 'string') {
          event[i].attendees = JSON.parse(event[i]?.attendees)
        }
        if (event[i].attendees) {
          let attendeesEmail = event[i].attendees.map((attendee) => {
            return attendee?.email
          })
          event[i].attendeesEmail = attendeesEmail
        }
        if (event[i].description) {
          var html = event[i].description
          var div = document.createElement('div')
          div.innerHTML = html
          event[i].text = div.innerText
          if (event[i].text) {
            event[i].text = event[i].text.replace(/\n/g, ' ')
          }
        }
        event[i].meta = JSON.parse(event[i]?.meta)

        tackleEvents.push({
          advisor: attendee.firstName + ' ' + attendee.lastName,
          title: event[i]?.summary,
          id: event[i].meta?.tackleId || '',
          comment: event[i]?.comment || '',
          description: event[i]?.description || '',
          text: event[i].text || '',
          timezone: event[i]?.timezone,
          eventDate,
          start: eventStartTime,
          end: eventEndTime,
          location: event[i].location,
          time: time,
          isAttendee,
          attendees: event[i]?.attendees,
          attendeesEmail: event[i].attendeesEmail,
          eventType: event[i]?.eventType,
          tackleId: event[i].meta?.tackleId || '',
          eventId: event[i]?.eventId || '',
          inviteeId: event[i]?.inviteeId,
          advisorId: user?.id,
          availability: event[i]?.availability,
          videoLinkDescription: event[i]?.videoLinkDescription,
          participantName: event[i]?.participantName,
          organizerName: event[i]?.organizerName,
          ownerId: event[i]?.ownerId,
          participantDetail: {
            firstName: event[i]?.participantFirstName,
            lastName: event[i]?.participantLastName,
          },
          organizerDetail: {
            firstName: event[i]?.organizerFirstName,
            lastName: event[i]?.organizerLastName,
          },
        })
      }

      return tackleEvents
    } catch (err) {
      console.log('error: ', err)
    }
  }
)

export const getCommentsValueSelector = createSelector([getClientsComment], (clients) => {
  let clientComments: any = []

  let clientMeeting
  if (clients?.tackleMeeting?.data) {
    clientMeeting = clients?.tackleMeeting?.data.map((meeting) => ({
      ...meeting,
      id: JSON.parse(meeting?.meta).tackleId,
    }))
  }

  if (clientMeeting) {
    for (let i = 0; i < clientMeeting.length; i++) {
      let updatedComment = clients?.updatedComments.find(
        (data) => data?.tackleId === clientMeeting[i]?.id
      )
      clientComments.push({
        id: clientMeeting[i].id || '',
        tenantId: clientMeeting[i].tenantId || '',
        comment: updatedComment ? updatedComment.comment : clientMeeting[i]?.comment,
      })
    }
  }
  return clientComments
})

export const getGroupedAllToolsByCategory = createSelector([getAllToolsData], (tools) => {
  const filteredCategories = groupToolsByCategoryHelper({ tools, filterEmptyCategories: true })
  return filteredCategories
})

export const getCalendarListDetails = createSelector([getToolsData], (tools: Tool[]) => {
  const calendarToolNames: string[] = [
    CYCLR_CALENDAR.GOOGLE_CALENDAR,
    CYCLR_CALENDAR.MICROSOFT_OFFICE_365,
  ]
  let calendarList = cloneDeep(CALENDARS_LIST)
  let hasExternalCalendarConnected = false
  tools.forEach((tool) => {
    if (
      calendarToolNames.includes(tool.toolName!) &&
      tool.connectionStatus &&
      ToolConnectionStatus.Disconnected !== tool.connectionStatus
    )
      hasExternalCalendarConnected = true
  })

  calendarList.forEach((calendar) => {
    const calendarTool = tools.find((tool) => tool.toolName === calendar.toolName)

    if (calendarTool && calendarTool.connectionStatus) {
      calendar.hasConnectionError =
        calendarTool.connectionStatus === ToolConnectionStatus.ConnectionError
      calendar.isConnected = calendarTool.connectionStatus !== ToolConnectionStatus.Disconnected
    }
  })

  calendarList = calendarList.filter((calendar) => calendar.isConnected)

  return { calendarList, hasExternalCalendarConnected }
})

export const getGroupedMyToolsByCategory = createSelector([getToolsData], (tools) => {
  const sortedCategories = groupToolsByCategoryHelper({ tools })
  return sortedCategories
})

export const getToolDetail = createSelector(
  [getToolsData, getAllToolsData],
  (myTools, allTools) => {
    const toolState: ExtendedTools[] = getCurrentRoute().includes(toolsTabs[0].link)
      ? myTools
      : allTools
    let queryParam = getReturnToPath()

    let tool = toolState.find((tool) => tool.id === queryParam?.toolId)

    if (tool) {
      tool = getToolFlyoutOptions(tool)
    }

    return tool || {}
  }
)
