import ComingSoonPlaceholder from 'components/Common/ComingSoonPlaceholder'
import React from 'react'

const BusinessData = () => {
  return (
    <div className="p-5">
      <ComingSoonPlaceholder
        title="Business Data"
        description="As GoTackle gets to know your clients, this area will offer you time-saving reports and critical data insights."
        className="flex flex-col items-center justify-center"
      />
    </div>
  )
}

export default BusinessData
