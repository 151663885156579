/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 45
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/gologicsolutions/GoTackle/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'production' | 'development'

export const ApiKey: Record<Environment, string> = {
  production: 'd64d818c98d978ed1fad719844da9578',
  development: 'c7b4d85989f2b493ca80101ed066948f',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '45',
    branch: 'main',
    source: 'web',
    versionId: 'cf3ef8fa-9434-4f86-a476-f7625733cd3a',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient }
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  email?: string
  firstName?: string
  lastName?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  roles?: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  supportOrgIds?: string[]
}

export interface AccountActivatedProperties {
  email: string
  id: string
}

export interface AccountCreatedProperties {
  email: string
  firstName: string
  id: string
  lastName: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  roles: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  supportOrgIds: string[]
}

export interface AccountDeactivatedProperties {
  email: string
  id: string
}

export interface AccountInvitationResentProperties {
  businessName?: string
  email: string
  name: string
  organizationName: string
  userId?: string
}

export interface AssessmentFinishedProperties {
  businessName?: string
  id: string
  industry?: string
  name: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  recommendedPlays?: string[]
  stage?: string
  userName?: string
}

export interface AssessmentQuestionAnsweredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  answerIds: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  answers: string[]
  id: string
  name: string
  question: string
  questionId: string
}

export interface AssessmentQuestionViewedProperties {
  id: string
  name: string
  question: string
  questionId: string
}

export interface AssessmentReviewedProperties {
  id: string
  name: string
  userId?: string
}

export interface AssessmentStartedProperties {
  id: string
  name: string
}

export interface BookmarkToolProperties {
  businessName?: string
  toolName: string
  userId?: string
  userName?: string
}

export interface ChartRangeChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartName?: string[]
  endDate: string
  section: string
  startDate: string
}

export interface ChartRangeViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  chartName?: string[]
  section: string
}

export interface CompletedAssessmentViewedByBoProperties {
  assessmentName: string
  businessName?: string
  industry?: string
  userName?: string
}

export interface CompletedPlayViewedByBoProperties {
  businessName?: string
  category: string
  currentTab: string
  industry?: string
  level: string
  playName: string
  userName?: string
}

export interface DashboardViewedProperties {
  previousPath: string
  triggeredFrom: string
}

export interface ImpactReportLinkClickedProperties {
  fileAction: string
  fileName: string
  organizationName: string
  userName?: string
}

export interface IndustryReportSearchedProperties {
  bsoOrgName?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  searchedKeyword?: string
  userId?: string
}

export interface LoggedInProperties {
  email: string
  firstName: string
  id: string
  lastName: string
}

export interface LoginFailedProperties {
  email: string
}

export interface MeetingBookedProperties {
  advisorName?: string
  bsoOrgName?: string
  description?: string
  duration: string
  endTime: string
  guests?: any
  initiatorRole: any
  meetingTitle: string
  originatorName?: string
  startTime: string
  videoLinkDescription?: string
}

export interface MeetingCanceledProperties {
  duration: string
  initiatorName: string
  initiatorRole: any
  meetingTitle: string
  originatorName?: string
}

export interface MeetingRequestedProperties {
  advisorName?: string
  bsoOrgName?: string
  clientBusinessName?: string
  clientName?: string
  duration: string
  guests?: any
  initiatorRole: any
  meetingTitle: string
  originatorName?: string
}

export interface MeetingUpdatedProperties {
  additionalDetails?: string
  guests?: any
  initiatorName: string
  initiatorRole: any
  location?: string
  title?: string
}

export interface MessageEditedProperties {
  editorName: string
  editorRole: string
  organizationName: string
}

export interface MessageSentProperties {
  content: string
  fileSize: any
  fileType: any
  organizationName: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  recipientIds: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  recipientNames: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  recipientRoles: string[]
  senderId: string
  senderName: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  senderRoles: string[]
}

export interface MonthlyImpactReportFormSubmittedProperties {
  additionalInfo?: string
  boID: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  businessBankAccount?: number
  businessName?: string
  businessStructure?: string
  businessStructureMonth?: string
  businessStructureYear?: string
  contractors?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  debtFinancing?: number
  education?: string
  educationLevel?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesContractors?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesFullTime?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesPartTime?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesVolunteers?: number
  financialGrowth?: string
  fullTimeEmployees?: string
  gender?: string
  impactReportCompleted: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  jobsCreated?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  locations?: number
  month?: string
  newCustomer?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  newCustomers?: number
  organizationName: string
  partTimeEmployees?: string
  physicalLocation?: string
  productSale?: string
  profitLoss?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  profitLossAmount?: number
  race?: string
  registerBusinessStructure?: string
  revenue?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  salesProducts?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  salesServices?: number
  serviceSale?: string
  targetAudience?: string
  userId?: string
  volunteers?: string
  wages?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wagesAveragePerHour?: number
  year?: string
}

export interface MonthlyImpactReportStatusChangedProperties {
  status: string
  userId?: string
}

export interface MonthlyImpactReportValuesChangedProperties {
  additionalInfo?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  businessBankAccount?: number
  businessName?: string
  businessStructure?: string
  businessStructureMonth?: string
  businessStructureYear?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  debtFinancing?: number
  educationLevel?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesContractors?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesFullTime?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesPartTime?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  employeesVolunteers?: number
  gender?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  jobsCreated?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  locations?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  newCustomers?: number
  profitLoss?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  profitLossAmount?: number
  race?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  salesProducts?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  salesServices?: number
  targetAudience?: string
  userName?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  wagesAveragePerHour?: number
}

export interface MonthlyImpactReportViewedByProperties {
  businessName?: string
  userName?: string
}

export interface MonthlyImpactReportViewedFromNotificationsProperties {
  businessName?: string
  notificationContent: string
  userName?: string
}

export interface MonthlyImpactReportViewedFromToastProperties {
  businessName?: string
  toastContent: string
  userName?: string
}

export interface NotificationClearedProperties {
  id: string
  originator: string
  type: string
}

export interface PasswordResetCompletedProperties {
  email: string
}

export interface PasswordResetInitiatedProperties {
  email: string
}

export interface PlayFinishedProperties {
  id: string
  name: string
}

export interface PlayReviewedProperties {
  id: string
  name: string
}

export interface PlayStartedProperties {
  id: string
  name: string
}

export interface ProfileUpdatedProperties {
  type: string
}

export interface RecommendedAssessmentViewedByBoProperties {
  assessmentName: string
  businessName?: string
  industry?: string
  openedAssessmentFrom: string
  userName?: string
}

export interface RecommendedPlayViewedByBoProperties {
  businessName?: string
  category: string
  industry?: string
  level: string
  openedPlayFrom: string
  playName: string
  userName?: string
}

export interface ReportOpenedProperties {
  reportName: string
}

export interface SmsSubscriptionStatusProperties {
  mobileContactNumber: string
  status: string
  userName?: string
}

export interface SubtabViewedProperties {
  subTabName: string
}

export interface ToolClosedProperties {
  id: string
  name: string
}

export interface ToolConnectedProperties {
  name: string
  playId?: string
}

export interface ToolDisconnectedProperties {
  name: string
}

export interface ToolLaunchedProperties {
  id: string
  name: string
}

export interface ToolReconnectedProperties {
  bsoOrgName?: string
  businessName?: string
  locationTriggered: string
  name: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  roles: string[]
  toolName: string
}

export interface ToolTabAccessedProperties {
  tabName: string
  toolName: string
}

export interface TopNavViewedProperties {
  tabName: string
}

export interface UserDisabledProperties {
  adminUserId: string
  adminUserName: string
  disabledUserId: string
  disabledUserName: string
  disabledUserRole: string
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class AccountActivated implements BaseEvent {
  event_type = 'Account Activated'

  constructor(public event_properties: AccountActivatedProperties) {
    this.event_properties = event_properties
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created'

  constructor(public event_properties: AccountCreatedProperties) {
    this.event_properties = event_properties
  }
}

export class AccountDeactivated implements BaseEvent {
  event_type = 'Account Deactivated'

  constructor(public event_properties: AccountDeactivatedProperties) {
    this.event_properties = event_properties
  }
}

export class AccountInvitationResent implements BaseEvent {
  event_type = 'Account Invitation Resent'

  constructor(public event_properties: AccountInvitationResentProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentFinished implements BaseEvent {
  event_type = 'Assessment Finished'

  constructor(public event_properties: AssessmentFinishedProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentQuestionAnswered implements BaseEvent {
  event_type = 'Assessment Question Answered'

  constructor(public event_properties: AssessmentQuestionAnsweredProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentQuestionViewed implements BaseEvent {
  event_type = 'Assessment Question Viewed'

  constructor(public event_properties: AssessmentQuestionViewedProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentReviewed implements BaseEvent {
  event_type = 'Assessment Reviewed'

  constructor(public event_properties: AssessmentReviewedProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentStarted implements BaseEvent {
  event_type = 'Assessment Started'

  constructor(public event_properties: AssessmentStartedProperties) {
    this.event_properties = event_properties
  }
}

export class BookmarkTool implements BaseEvent {
  event_type = 'Bookmark tool'

  constructor(public event_properties: BookmarkToolProperties) {
    this.event_properties = event_properties
  }
}

export class ChartRangeChanged implements BaseEvent {
  event_type = 'Chart Range Changed'

  constructor(public event_properties: ChartRangeChangedProperties) {
    this.event_properties = event_properties
  }
}

export class ChartRangeViewed implements BaseEvent {
  event_type = 'Chart Range Viewed'

  constructor(public event_properties: ChartRangeViewedProperties) {
    this.event_properties = event_properties
  }
}

export class CompletedAssessmentViewedByBo implements BaseEvent {
  event_type = 'Completed Assessment Viewed by BO'

  constructor(public event_properties: CompletedAssessmentViewedByBoProperties) {
    this.event_properties = event_properties
  }
}

export class CompletedPlayViewedByBo implements BaseEvent {
  event_type = 'Completed Play Viewed by BO'

  constructor(public event_properties: CompletedPlayViewedByBoProperties) {
    this.event_properties = event_properties
  }
}

export class DashboardViewed implements BaseEvent {
  event_type = 'Dashboard Viewed'

  constructor(public event_properties: DashboardViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ImpactReportLinkClicked implements BaseEvent {
  event_type = 'Impact Report Link Clicked'

  constructor(public event_properties: ImpactReportLinkClickedProperties) {
    this.event_properties = event_properties
  }
}

export class IndustryReportSearched implements BaseEvent {
  event_type = 'Industry Report Searched'

  constructor(public event_properties?: IndustryReportSearchedProperties) {
    this.event_properties = event_properties
  }
}

export class LoggedIn implements BaseEvent {
  event_type = 'Logged In'

  constructor(public event_properties: LoggedInProperties) {
    this.event_properties = event_properties
  }
}

export class LoggedOut implements BaseEvent {
  event_type = 'Logged Out'
}

export class LoginFailed implements BaseEvent {
  event_type = 'Login Failed'

  constructor(public event_properties: LoginFailedProperties) {
    this.event_properties = event_properties
  }
}

export class LoginPrompted implements BaseEvent {
  event_type = 'Login Prompted'
}

export class MeetingBooked implements BaseEvent {
  event_type = 'Meeting booked'

  constructor(public event_properties: MeetingBookedProperties) {
    this.event_properties = event_properties
  }
}

export class MeetingCanceled implements BaseEvent {
  event_type = 'Meeting Canceled'

  constructor(public event_properties: MeetingCanceledProperties) {
    this.event_properties = event_properties
  }
}

export class MeetingRequested implements BaseEvent {
  event_type = 'Meeting Requested'

  constructor(public event_properties: MeetingRequestedProperties) {
    this.event_properties = event_properties
  }
}

export class MeetingUpdated implements BaseEvent {
  event_type = 'Meeting Updated'

  constructor(public event_properties: MeetingUpdatedProperties) {
    this.event_properties = event_properties
  }
}

export class MessageEdited implements BaseEvent {
  event_type = 'Message Edited'

  constructor(public event_properties: MessageEditedProperties) {
    this.event_properties = event_properties
  }
}

export class MessageSent implements BaseEvent {
  event_type = 'Message Sent'

  constructor(public event_properties: MessageSentProperties) {
    this.event_properties = event_properties
  }
}

export class MonthlyImpactReportFormSubmitted implements BaseEvent {
  event_type = 'Monthly Impact Report Form Submitted'

  constructor(public event_properties: MonthlyImpactReportFormSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export class MonthlyImpactReportStatusChanged implements BaseEvent {
  event_type = 'Monthly Impact Report Status Changed'

  constructor(public event_properties: MonthlyImpactReportStatusChangedProperties) {
    this.event_properties = event_properties
  }
}

export class MonthlyImpactReportValuesChanged implements BaseEvent {
  event_type = 'Monthly Impact Report Values Changed'

  constructor(public event_properties?: MonthlyImpactReportValuesChangedProperties) {
    this.event_properties = event_properties
  }
}

export class MonthlyImpactReportViewedBy implements BaseEvent {
  event_type = 'Monthly Impact Report Viewed By'

  constructor(public event_properties?: MonthlyImpactReportViewedByProperties) {
    this.event_properties = event_properties
  }
}

export class MonthlyImpactReportViewedFromNotifications implements BaseEvent {
  event_type = 'Monthly Impact Report Viewed From Notifications'

  constructor(public event_properties: MonthlyImpactReportViewedFromNotificationsProperties) {
    this.event_properties = event_properties
  }
}

export class MonthlyImpactReportViewedFromToast implements BaseEvent {
  event_type = 'Monthly Impact Report Viewed From Toast'

  constructor(public event_properties: MonthlyImpactReportViewedFromToastProperties) {
    this.event_properties = event_properties
  }
}

export class NewMessageInitiated implements BaseEvent {
  event_type = 'New Message Initiated'
}

export class NotificationCleared implements BaseEvent {
  event_type = 'Notification Cleared'

  constructor(public event_properties: NotificationClearedProperties) {
    this.event_properties = event_properties
  }
}

export class NotificationsCleared implements BaseEvent {
  event_type = 'Notifications Cleared'
}

export class NotificationsViewed implements BaseEvent {
  event_type = 'Notifications Viewed'
}

export class PasswordResetCompleted implements BaseEvent {
  event_type = 'Password Reset Completed'

  constructor(public event_properties: PasswordResetCompletedProperties) {
    this.event_properties = event_properties
  }
}

export class PasswordResetInitiated implements BaseEvent {
  event_type = 'Password Reset Initiated'

  constructor(public event_properties: PasswordResetInitiatedProperties) {
    this.event_properties = event_properties
  }
}

export class PlayFinished implements BaseEvent {
  event_type = 'Play Finished'

  constructor(public event_properties: PlayFinishedProperties) {
    this.event_properties = event_properties
  }
}

export class PlayReviewed implements BaseEvent {
  event_type = 'Play Reviewed'

  constructor(public event_properties: PlayReviewedProperties) {
    this.event_properties = event_properties
  }
}

export class PlayStarted implements BaseEvent {
  event_type = 'Play Started'

  constructor(public event_properties: PlayStartedProperties) {
    this.event_properties = event_properties
  }
}

export class ProfileUpdated implements BaseEvent {
  event_type = 'Profile Updated'

  constructor(public event_properties: ProfileUpdatedProperties) {
    this.event_properties = event_properties
  }
}

export class RecommendedAssessmentViewedByBo implements BaseEvent {
  event_type = 'Recommended Assessment Viewed By BO'

  constructor(public event_properties: RecommendedAssessmentViewedByBoProperties) {
    this.event_properties = event_properties
  }
}

export class RecommendedPlayViewedByBo implements BaseEvent {
  event_type = 'Recommended Play Viewed By BO'

  constructor(public event_properties: RecommendedPlayViewedByBoProperties) {
    this.event_properties = event_properties
  }
}

export class ReportOpened implements BaseEvent {
  event_type = 'Report Opened'

  constructor(public event_properties: ReportOpenedProperties) {
    this.event_properties = event_properties
  }
}

export class SmsSubscriptionStatus implements BaseEvent {
  event_type = 'SmsSubscriptionStatus'

  constructor(public event_properties: SmsSubscriptionStatusProperties) {
    this.event_properties = event_properties
  }
}

export class SubtabViewed implements BaseEvent {
  event_type = 'Subtab Viewed'

  constructor(public event_properties: SubtabViewedProperties) {
    this.event_properties = event_properties
  }
}

export class ToolClosed implements BaseEvent {
  event_type = 'Tool Closed'

  constructor(public event_properties: ToolClosedProperties) {
    this.event_properties = event_properties
  }
}

export class ToolConnected implements BaseEvent {
  event_type = 'Tool Connected'

  constructor(public event_properties: ToolConnectedProperties) {
    this.event_properties = event_properties
  }
}

export class ToolDisconnected implements BaseEvent {
  event_type = 'Tool Disconnected'

  constructor(public event_properties: ToolDisconnectedProperties) {
    this.event_properties = event_properties
  }
}

export class ToolLaunched implements BaseEvent {
  event_type = 'Tool Launched'

  constructor(public event_properties: ToolLaunchedProperties) {
    this.event_properties = event_properties
  }
}

export class ToolReconnected implements BaseEvent {
  event_type = 'Tool Reconnected'

  constructor(public event_properties: ToolReconnectedProperties) {
    this.event_properties = event_properties
  }
}

export class ToolTabAccessed implements BaseEvent {
  event_type = 'Tool Tab Accessed'

  constructor(public event_properties: ToolTabAccessedProperties) {
    this.event_properties = event_properties
  }
}

export class TopNavViewed implements BaseEvent {
  event_type = 'Top Nav Viewed'

  constructor(public event_properties: TopNavViewedProperties) {
    this.event_properties = event_properties
  }
}

export class UserDisabled implements BaseEvent {
  event_type = 'User Disabled'

  constructor(public event_properties: UserDisabledProperties) {
    this.event_properties = event_properties
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account Activated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Account%20Activated)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  accountActivated(
    properties: AccountActivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountActivated(properties), options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Account%20Created)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * Account Deactivated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Account%20Deactivated)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  accountDeactivated(
    properties: AccountDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountDeactivated(properties), options);
  }

  /**
   * Account Invitation Resent
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Account%20Invitation%20Resent)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  accountInvitationResent(
    properties: AccountInvitationResentProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountInvitationResent(properties), options);
  }

  /**
   * Assessment Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Assessment%20Finished)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  assessmentFinished(
    properties: AssessmentFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentFinished(properties), options);
  }

  /**
   * Assessment Question Answered
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Assessment%20Question%20Answered)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. answerIds)
   * @param options Amplitude event options.
   */
  assessmentQuestionAnswered(
    properties: AssessmentQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentQuestionAnswered(properties), options);
  }

  /**
   * Assessment Question Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Assessment%20Question%20Viewed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  assessmentQuestionViewed(
    properties: AssessmentQuestionViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentQuestionViewed(properties), options);
  }

  /**
   * Assessment Reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Assessment%20Reviewed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  assessmentReviewed(
    properties: AssessmentReviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentReviewed(properties), options);
  }

  /**
   * Assessment Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Assessment%20Started)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  assessmentStarted(
    properties: AssessmentStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentStarted(properties), options);
  }

  /**
   * Bookmark tool
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Bookmark%20tool)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  bookmarkTool(
    properties: BookmarkToolProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookmarkTool(properties), options);
  }

  /**
   * Chart Range Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Chart%20Range%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. chartName)
   * @param options Amplitude event options.
   */
  chartRangeChanged(
    properties: ChartRangeChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartRangeChanged(properties), options);
  }

  /**
   * Chart Range Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Chart%20Range%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. chartName)
   * @param options Amplitude event options.
   */
  chartRangeViewed(
    properties: ChartRangeViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartRangeViewed(properties), options);
  }

  /**
   * Completed Assessment Viewed by BO
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Completed%20Assessment%20Viewed%20by%20BO)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. assessmentName)
   * @param options Amplitude event options.
   */
  completedAssessmentViewedByBo(
    properties: CompletedAssessmentViewedByBoProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompletedAssessmentViewedByBo(properties), options);
  }

  /**
   * Completed Play Viewed by BO
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Completed%20Play%20Viewed%20by%20BO)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  completedPlayViewedByBo(
    properties: CompletedPlayViewedByBoProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompletedPlayViewedByBo(properties), options);
  }

  /**
   * Dashboard Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Dashboard%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. previousPath)
   * @param options Amplitude event options.
   */
  dashboardViewed(
    properties: DashboardViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardViewed(properties), options);
  }

  /**
   * Impact Report Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Impact%20Report%20Link%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fileAction)
   * @param options Amplitude event options.
   */
  impactReportLinkClicked(
    properties: ImpactReportLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImpactReportLinkClicked(properties), options);
  }

  /**
   * Industry Report Searched
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Industry%20Report%20Searched)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. bsoOrgName)
   * @param options Amplitude event options.
   */
  industryReportSearched(
    properties?: IndustryReportSearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IndustryReportSearched(properties), options);
  }

  /**
   * Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Logged%20In)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  loggedIn(
    properties: LoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedIn(properties), options);
  }

  /**
   * Logged Out
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Logged%20Out)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  loggedOut(
    options?: EventOptions,
  ) {
    return this.track(new LoggedOut(), options);
  }

  /**
   * Login Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Login%20Failed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  loginFailed(
    properties: LoginFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginFailed(properties), options);
  }

  /**
   * Login Prompted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Login%20Prompted)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  loginPrompted(
    options?: EventOptions,
  ) {
    return this.track(new LoginPrompted(), options);
  }

  /**
   * Meeting booked
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Meeting%20booked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. advisorName)
   * @param options Amplitude event options.
   */
  meetingBooked(
    properties: MeetingBookedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeetingBooked(properties), options);
  }

  /**
   * Meeting Canceled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Meeting%20Canceled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. duration)
   * @param options Amplitude event options.
   */
  meetingCanceled(
    properties: MeetingCanceledProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeetingCanceled(properties), options);
  }

  /**
   * Meeting Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Meeting%20Requested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. advisorName)
   * @param options Amplitude event options.
   */
  meetingRequested(
    properties: MeetingRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeetingRequested(properties), options);
  }

  /**
   * Meeting Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Meeting%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. additionalDetails)
   * @param options Amplitude event options.
   */
  meetingUpdated(
    properties: MeetingUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MeetingUpdated(properties), options);
  }

  /**
   * Message Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Message%20Edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. editorName)
   * @param options Amplitude event options.
   */
  messageEdited(
    properties: MessageEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageEdited(properties), options);
  }

  /**
   * Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Message%20Sent)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. content)
   * @param options Amplitude event options.
   */
  messageSent(
    properties: MessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSent(properties), options);
  }

  /**
   * Monthly Impact Report Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Monthly%20Impact%20Report%20Form%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. additionalInfo)
   * @param options Amplitude event options.
   */
  monthlyImpactReportFormSubmitted(
    properties: MonthlyImpactReportFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MonthlyImpactReportFormSubmitted(properties), options);
  }

  /**
   * Monthly Impact Report Status Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Monthly%20Impact%20Report%20Status%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  monthlyImpactReportStatusChanged(
    properties: MonthlyImpactReportStatusChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MonthlyImpactReportStatusChanged(properties), options);
  }

  /**
   * Monthly Impact Report Values Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Monthly%20Impact%20Report%20Values%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. additionalInfo)
   * @param options Amplitude event options.
   */
  monthlyImpactReportValuesChanged(
    properties?: MonthlyImpactReportValuesChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MonthlyImpactReportValuesChanged(properties), options);
  }

  /**
   * Monthly Impact Report Viewed By
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Monthly%20Impact%20Report%20Viewed%20By)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  monthlyImpactReportViewedBy(
    properties?: MonthlyImpactReportViewedByProperties,
    options?: EventOptions,
  ) {
    return this.track(new MonthlyImpactReportViewedBy(properties), options);
  }

  /**
   * Monthly Impact Report Viewed From Notifications
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Monthly%20Impact%20Report%20Viewed%20From%20Notifications)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  monthlyImpactReportViewedFromNotifications(
    properties: MonthlyImpactReportViewedFromNotificationsProperties,
    options?: EventOptions,
  ) {
    return this.track(new MonthlyImpactReportViewedFromNotifications(properties), options);
  }

  /**
   * Monthly Impact Report Viewed From Toast
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Monthly%20Impact%20Report%20Viewed%20From%20Toast)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  monthlyImpactReportViewedFromToast(
    properties: MonthlyImpactReportViewedFromToastProperties,
    options?: EventOptions,
  ) {
    return this.track(new MonthlyImpactReportViewedFromToast(properties), options);
  }

  /**
   * New Message Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/New%20Message%20Initiated)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  newMessageInitiated(
    options?: EventOptions,
  ) {
    return this.track(new NewMessageInitiated(), options);
  }

  /**
   * Notification Cleared
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Notification%20Cleared)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  notificationCleared(
    properties: NotificationClearedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationCleared(properties), options);
  }

  /**
   * Notifications Cleared
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Notifications%20Cleared)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  notificationsCleared(
    options?: EventOptions,
  ) {
    return this.track(new NotificationsCleared(), options);
  }

  /**
   * Notifications Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Notifications%20Viewed)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  notificationsViewed(
    options?: EventOptions,
  ) {
    return this.track(new NotificationsViewed(), options);
  }

  /**
   * Password Reset Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Password%20Reset%20Completed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  passwordResetCompleted(
    properties: PasswordResetCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetCompleted(properties), options);
  }

  /**
   * Password Reset Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Password%20Reset%20Initiated)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  passwordResetInitiated(
    properties: PasswordResetInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetInitiated(properties), options);
  }

  /**
   * Play Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Play%20Finished)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  playFinished(
    properties: PlayFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayFinished(properties), options);
  }

  /**
   * Play Reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Play%20Reviewed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  playReviewed(
    properties: PlayReviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayReviewed(properties), options);
  }

  /**
   * Play Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Play%20Started)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  playStarted(
    properties: PlayStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayStarted(properties), options);
  }

  /**
   * Profile Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Profile%20Updated)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  profileUpdated(
    properties: ProfileUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProfileUpdated(properties), options);
  }

  /**
   * Recommended Assessment Viewed By BO
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Recommended%20Assessment%20Viewed%20By%20BO)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. assessmentName)
   * @param options Amplitude event options.
   */
  recommendedAssessmentViewedByBo(
    properties: RecommendedAssessmentViewedByBoProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendedAssessmentViewedByBo(properties), options);
  }

  /**
   * Recommended Play Viewed By BO
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Recommended%20Play%20Viewed%20By%20BO)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. businessName)
   * @param options Amplitude event options.
   */
  recommendedPlayViewedByBo(
    properties: RecommendedPlayViewedByBoProperties,
    options?: EventOptions,
  ) {
    return this.track(new RecommendedPlayViewedByBo(properties), options);
  }

  /**
   * Report Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Report%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. reportName)
   * @param options Amplitude event options.
   */
  reportOpened(
    properties: ReportOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportOpened(properties), options);
  }

  /**
   * SmsSubscriptionStatus
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/SmsSubscriptionStatus)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. mobileContactNumber)
   * @param options Amplitude event options.
   */
  smsSubscriptionStatus(
    properties: SmsSubscriptionStatusProperties,
    options?: EventOptions,
  ) {
    return this.track(new SmsSubscriptionStatus(properties), options);
  }

  /**
   * Subtab Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Subtab%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. subTabName)
   * @param options Amplitude event options.
   */
  subtabViewed(
    properties: SubtabViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubtabViewed(properties), options);
  }

  /**
   * Tool Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Tool%20Closed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  toolClosed(
    properties: ToolClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToolClosed(properties), options);
  }

  /**
   * Tool Connected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Tool%20Connected)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  toolConnected(
    properties: ToolConnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToolConnected(properties), options);
  }

  /**
   * Tool Disconnected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Tool%20Disconnected)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  toolDisconnected(
    properties: ToolDisconnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToolDisconnected(properties), options);
  }

  /**
   * Tool Launched
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Tool%20Launched)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  toolLaunched(
    properties: ToolLaunchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToolLaunched(properties), options);
  }

  /**
   * Tool Reconnected
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Tool%20Reconnected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. bsoOrgName)
   * @param options Amplitude event options.
   */
  toolReconnected(
    properties: ToolReconnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToolReconnected(properties), options);
  }

  /**
   * Tool Tab Accessed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Tool%20Tab%20Accessed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. tabName)
   * @param options Amplitude event options.
   */
  toolTabAccessed(
    properties: ToolTabAccessedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToolTabAccessed(properties), options);
  }

  /**
   * Top Nav Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/Top%20Nav%20Viewed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. tabName)
   * @param options Amplitude event options.
   */
  topNavViewed(
    properties: TopNavViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TopNavViewed(properties), options);
  }

  /**
   * User Disabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/GoTackle/events/main/latest/User%20Disabled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. adminUserId)
   * @param options Amplitude event options.
   */
  userDisabled(
    properties: UserDisabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserDisabled(properties), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
