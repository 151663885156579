import {
  Query,
  Mutation,
  SortOrder,
  UserFile,
  UserProfile,
  CalendarEvent,
  SetUserMeetingInput,
  Tool,
  GetOAuthUrlInput,
} from '__generated__/api-types-and-hooks'
import { FC } from 'react'
import { CALENDAR, USER_ROLES } from '../config'
import { ReduxAction } from 'store/types'

/** Common props passed to many react components */
export interface CommonProps {
  className?: string
  id?: string
  onClick?: (...args: any[]) => any
  testId?: string
}

export type FontSize =
  | 'xs'
  | 'sm'
  | 'tiny'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'

export type FontWeight =
  | 'font-thin'
  | 'font-extralight'
  | 'font-light'
  | 'font-normal'
  | 'font-medium'
  | 'font-semibold'
  | 'font-bold'
  | 'font-extrabold'
  | 'font-black'

/** Easily type components with React.FC, common props, and component-specific props */
export type AppFC<Props = {}> = FC<CommonProps & Props>
/** Remaps the keys/values types of T to those of type R. Unknown keys are ignored. */
export type OverwriteProperties<T, R> = R extends { [K in keyof T]: unknown }
  ? Omit<T, keyof R> & R
  : T
/** Make the properties named K optional in type T */
export type PartializeProperties<T, K extends keyof T> = Omit<T, K> & { [k in K]?: T[K] }
/** Construct a type with the properties of O whose values are of ReplacementType */
export type RetypeObjectValues<O extends { [key: string]: unknown }, ReplacementType> = {
  [Key in keyof O]: ReplacementType
}
/** Construct a type whose named properties and typed values match those of type O, but whose values can also be ExtraType */
export type AddValuesType<O extends { [key: string]: unknown }, ExtraType> = {
  [Key in keyof O]: O[Key] | ExtraType
}
/** The app's GraphQL "Mutation" and "Query" types combined (with private properties removed) */
export type Operations = Required<Omit<Query & Mutation, '__typename'>>
/** The names of all the app's queries and mutations */
export type OperationName = keyof Operations
/** An object type with a single property (named for a GraphQL operation) whose value is the type of the resuling data */
export type QueryResponse<N extends OperationName> = Pick<Operations, N>
/** A type corresponding to a query response without the possibility of the return payload being null or undefined */
export type SuccessfulQueryResponse<N extends OperationName> = {
  [K in N]: NonNullable<QueryResponse<N>[K]>
}
/** The type of the payload returned from the GraphQL operation named "N" */
export type OperationResponseData<N extends OperationName> = QueryResponse<N>[N]

export type Columns = IColumn[]

/** Construct a union type representing all possible types of values found in object type T  */
export type Values<T> = T[keyof T]

export interface IColumn {
  text?: string
  field?: string
  width?: number
  tableType?: string
  headerName?: string
  direction?: string
  headerButton?: JSX.Element
  targets?: number
  colSpan?: string
  textDirection?: string
  colFields: IColumnField[]
  renderCell?: (params: any) => JSX.Element
}

export interface IGetOAuthUrlAction extends GetOAuthUrlInput {
  calendar?: string
}

export interface IColumnField {
  field?: string
  type?: string
  circle?: boolean
  paddingTop?: string
  headerName?: string
  sortable?: boolean
  headerButton?: JSX.Element
  sortingOrder?: SortOrder.Ascending | SortOrder.Descending
}

export type RecommendationCategory = 'play' | 'assessment'

export interface ICalendarEvent {
  calendar: string
  title: string
  start: Date
  end: Date
  attendeesEmail: string[]
  description?: string
  location: string
  timezone: string
  attendees: ICalendarEventAttendee[]
  eventType: CALENDAR
  organizer?: string
  inviteeId?: string | null
  eventId: string
  advisorId: string | null
  advisor?: string
  id: string
  tackleId?: string
  availability?: any
  startDateTime: string
  endDateTime: string
  videoLinkDescription?: string | null
  text?: string
  textToShow?: string
  meeting?: string
}

export interface ICalendarEventAttendee {
  email: string
  responseStatus: string
  optional?: boolean
}
export interface IUser {
  userName: string
  businessName: string
  industry: string
}
export interface IAssessmentResponse {
  label: string
  id: string
  value: string | Array<IAssessmentResponse>
}
export interface IAssessmentResponseObject {
  assessmentResponse: Array<IAssessmentResponse>
}
export interface IOption {
  label: string
  value: string
}

export interface IAssessmentOptions {
  businessStage: Array<IOption>
  businessClassification: Array<IOption>
}
export interface IResponse {
  id: string
  next: string
  value: string
  label: string | UserFile | UserFile[] | Array<string>
  questionLabel: string
  showInReview: boolean
  optionType: string
  previous: string[]
}
export interface IReviewAssessment {
  userId: string
  businessName: string
}
export interface IReviewAssessmentResponses {
  userId: string
  userName: string
  businessName: string
  assessmentType: string
  resetAssessment: () => void
  setCompletedAssessmentAt?: (payload) => void
}
export interface IReviewAssessmentHeader {
  assessmentType: string
  completedAssessmentAt: string
}
export interface IFileInfo {
  fileType: string
  fileSize: string
}
export interface IUserSummary {
  firstName: string
  lastName: string
  mobileContactNumber: string
  smsSubscriptionStatus: string
  roles?: [USER_ROLES]
}

export interface AdvisorStepper {
  title: string
  value: string
}
export interface ICreateProfile {
  userId: string
  moveToNext: () => void
  handleBack: (payload) => void
  activeStep: string
  updateProgressbarAction: () => {}
  updateUserProfileAction: (payload) => void
  updateUserBusinessProfileAction: (payload) => void
}
export type Action<T, P> = {
  type: ReduxAction<T>
  payload: P
}
export interface IOptionsItem {
  industry: string
  label: string
  value: string
  toolTipText: string
}
export interface IOptions {
  [key: string]: IOptionsItem[]
}

export interface ISmsSubscriptionNotification {
  user: IUserSummary
  onSubmit: (payload) => void
  isLoading: boolean
  moveToNext?: () => {} | void
  moveToNextTwo?: () => {} | void
  handleBack?: () => {} | void
  isWelcomeScreen: boolean
  progressBarAction?: () => void
}

export interface IPlayTab {
  tabData: {
    steps: {
      stepData: IStep[]
    }[]
  }
  id: string
}
export interface IPlayStage {
  steps: IStep[]
  id?: string
  isCompleted?: boolean
}

export interface IStep {
  isCompleted: boolean
}

export type CustomFile = File & { invalid: boolean }
export enum ToolConnectionStep {
  CONNECTING = 'Connecting',
  CONNECTION_FORM = 'ConnectionForm',
}

export interface IAccountsBusinessFormValues {
  EIN: string
  year: string
  month: string
  naicsCode: string
  domainName: string
  volunteers: string
  contractors: string
  businessName: string
  businessEmail: string
  yearsInBusiness: string
  topGoalOptionId: string
  partTimeEmployees: string
  fullTimeEmployees: string
  businessStageOptionId: string
  businessNatureOptionId: string
  registerBusinessStructure: string
  supportAndHelpOptionIds: string[]
  foodSubIndustryOptionIds: string[]
  businessLocationsOptionIds: string[]
  strongBusinessAreasOptionIds: string[]
  servicesSubIndustryOptionId: string
  businessClassificationOptionId: string
  registerBusinessStructureOptionId: string
}

export type UserBasicInfo = Pick<UserProfile, 'firstName' | 'lastName'>
export type extendedCalendarEvents = CalendarEvent & {
  title: string
  attendeesEmail: string[]
  participantDetail: UserBasicInfo
  organizerDetail: UserBasicInfo
  tackleId: string
}
export enum TestIds {
  ADD_MEETING_COMMENT_BUTTON = 'meeting-comment-button',
  SAVE_MEETING_COMMENT_BUTTON = 'save-meeting-comment-button',
}

export type UpdatedSetUserMeetingInput = SetUserMeetingInput & {
  businessName?: string
  duration?: string
  organizationName?: string
}

export type BasicInfo = {
  title: string
  name: string
  isVisible?: boolean
}

export type ExtendedTools = Tool & {
  toolFlyoutOptions?: BasicInfo[]
}
export interface IUserRecommendations {
  id: string
  meta: IMeta
  description: string
}

export interface IMeta {
  order?: string
  industry?: string
  type?: string
  isCompleted?: boolean
  assessmentType?: string
  showToClientTools?: boolean
  questionType?: string
  playBookTitle?: string
  category?: string
}

export interface ITimeDurationAvailabilityOptions {
  label: string
  value: string
}

interface ITimeSlots {
  label: string
  value: string
  checked?: boolean
  slots: ISlots[]
}

interface ISlots {
  startTime: string
  endTime: string
}

export interface IAdvisorForm {
  timeSlots: ITimeSlots[]
  timeZone: string
  duration: string[]
  defaultVideoLinkDescription?: string
}
export interface IAssessmentQuestion {
  id: string
  meta: string
  question: string
  value: string
}

export interface IPlayResponse {
  [key: string]: {
    value: {
      [key: string]: string
    }
  }
}
