import get from 'lodash/get'
import first from 'lodash/first'
import { find, split } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import hookForms from 'utils/hookForms'
import { fileExtension, FORM, UPLOAD_TYPE } from 'config'
import { getExt } from 'components/Common/Dropzone/utils'
import { sanitize } from 'dompurify'

export enum IOptionTypes {
  SUCCESS_DESCRIPTION = 'successDescription',
  BUTTON = 'button',
  INPUT = 'input',
  UPLOAD = 'upload',
  PLAY_CHECKBOX = 'play-checkbox',
  QUESTION = 'question',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  SELECT_FIELD = 'select-field',
  NUMERIC = 'input-numeric-slots',
  MULTI_FIELD = 'multi-field',
  CURRENCY = 'currency',
}

export const registerQuestionType = (optionType: IOptionTypes) => {
  return {
    isSuccessDescription: optionType === IOptionTypes.SUCCESS_DESCRIPTION,
    isButton: optionType === IOptionTypes.BUTTON,
    isInput: optionType === IOptionTypes.INPUT,
    isSelectField: optionType === IOptionTypes.SELECT_FIELD,
    isNumeric: optionType === IOptionTypes.NUMERIC,
    isMultiField: optionType === IOptionTypes.MULTI_FIELD,
    isUpload: optionType === IOptionTypes.UPLOAD,
    isPlayCheckbox: optionType === IOptionTypes.PLAY_CHECKBOX,
    isQuestion: optionType === IOptionTypes.QUESTION,
    isCheckbox: optionType === IOptionTypes.CHECKBOX,
    isSelect: optionType === IOptionTypes.SELECT,
    isCurrency: optionType === IOptionTypes.CURRENCY,
    isButtonOrInput: includes(
      [IOptionTypes.BUTTON, IOptionTypes.INPUT, IOptionTypes.NUMERIC, IOptionTypes.MULTI_FIELD],
      optionType
    ),
    isUploadOrInput: includes([IOptionTypes.UPLOAD, IOptionTypes.INPUT], optionType),
    isButtonOrSuccessDescription: includes(
      [IOptionTypes.SUCCESS_DESCRIPTION, IOptionTypes.BUTTON],
      optionType
    ),
  }
}

export const getButtonLabel = (question, questionType) => {
  return questionType.isNumeric ? 'Submit' : get(question, 'options[0].value', 'Submit')
}

export const getNextQuestion = (allowMultiSelect, answers) => {
  if (allowMultiSelect) return get(first(answers), 'action.next.default')
  return answers?.action?.next?.default
}

export const getNumericInputOption = (options, answer) => {
  const selectedAnswer = find(options, (option) => {
    const values = split(option.value, '-')
    if (answer >= Number(get(values, '0')) && answer <= Number(get(values, '1'))) return true
    return false
  })
  if (selectedAnswer) return selectedAnswer

  const defaultValue = find(options, (option) => includes(option.value, '*'))
  if (defaultValue) return defaultValue

  const submitOption = find(options, (option) => option.value === 'Submit')
  return submitOption
}

export const checkFileType = (uploadedFileExtension) => {
  const isImage = fileExtension.image.some((type) => uploadedFileExtension.includes(type))
  return isImage
}

export const checkMultiInputValue = (inputMultiValue) => {
  if (!inputMultiValue) return false
  const isMultiInputValueExist = inputMultiValue.every((data) => isEmpty(data.value))
  return isMultiInputValueExist
}

export const checkFileTypeHandler = (uploadType, fileData, fileExtensions) => {
  let isValid: boolean = false
  const uploadedFileExtension: string = getExt(fileData[0]?.name)

  if (uploadType === UPLOAD_TYPE.IMAGE) {
    isValid = checkFileType(uploadedFileExtension)
  } else if (uploadType !== UPLOAD_TYPE.IMAGE) {
    let fileType = fileExtensions ? fileExtensions : [...fileExtension.word, ...fileExtension.pdf]
    if (Array.isArray(fileType) && !isEmpty(fileType)) {
      isValid = fileType.some((type) => uploadedFileExtension.includes(type))
    }
  }
  return { isError: !isValid, uploadedFile: isValid ? fileData[0] : null }
}
export function createMarkup(html, user, businessName) {
  const businessProfileForm = hookForms.getForm(FORM.BUSINESS_PROFILE)
  const profilesForm = hookForms.getForm(FORM.PROFILE_FORM)

  return {
    __html: sanitize(
      html
        .replaceAll(
          '$[business_name]',
          businessName ||
            businessProfileForm?.getValues()?.businessName ||
            get(user, 'businessName', '') ||
            'your business'
        )
        .replaceAll(
          '$[first_name]',
          profilesForm?.getValues()?.firstName || get(user, 'firstName', '') || 'your Name'
        )
    ),
  }
}
