import { MEETING_STEPPER } from 'config'
import {
  CANCEL_USER_CALENDAR_EVENT,
  GET_ADVISOR_PROFILE,
  GET_USER_AVAILABILITY_HOURS,
  GET_USER_MEETING_BY_EVENT_ID,
  OPEN_MEETING_CONFIRMATION,
  RESET_PLAY,
  SET_AUTH_TOOLS_SUCCESS,
  SET_CURRENT_CALENDAR_DATE,
  SET_IS_SCHEDULED,
  SET_TOOL_CONNECTION_STATUS,
  SET_USER_MEETING,
  UPDATE_USER_AVAILABILITY_HOURS,
} from './../types'
import {
  GET_USER_CONNECTORS,
  GET_OAUTH_URL,
  RESET_OAUTH_URL,
  GET_CALENDLY_USER,
  GET_USER_CALENDAR_EVENTS,
  GET_BO_ADVISOR,
  GET_USER_MEETING,
  HANDLE_CYCLR_OAUTH,
  UPDATE_CALENDAR_MEETING,
  GET_MEETING_TYPES,
  SET_USER_AVAILABILITY,
  GET_USER_AVAILABILITY,
  OPEN_SLOTS_MODAL,
  UPDATE_MEETING_STEPPER,
} from 'store/types'
import { CYCLR_CALENDAR, STATUS } from 'config'

const initialState = {
  userConnectors: [],
  oAuthUrl: '',
  calendarEvents: [],
  calendlyCalendar: {},
  boAdvisors: [],
  boAdvisorsLoading: true,
  cancelMeetingLoading: false,
  isScheduled: false,
  isConnected: false,
  updatedCalendar: '',
  meetingTypes: [],
  userAvailability: '',
  userAvailabilityList: [],
  advisorProfile: null,
  userAvailabilityStatus: '',
  googleLoadingStatus: '',
  outLookLoadingStatus: '',
  calendlyLoadingStatus: '',
  isMeetingModalOpen: false,
  isSlotsModalOpen: false,
  status: '',
  meetingStepper: MEETING_STEPPER.ADVISOR,
  loadingStatus: { calendlyLoadingStatus: '', googleLoadingStatus: '', outLookLoadingStatus: '' },
  userAvailabilityHoursList: {},
  calendarEvent: {},
  isDisabled: false,
  errorMessage: '',
  calendarConnectionSuccess: false,
  stepType: '',
  isMeetingDeletion: false,
  eventId: '',
  isUpdateUserAvailabilityLoading: false,
  currentCalendarDate: new Date(),
  isToolConnectionSuccessLoading: false,
}

const getOAuthLoading = (state, payload, statusType) => {
  let loadingStatus = state.loadingStatus

  if (payload.calendar === CYCLR_CALENDAR.CALENDLY) {
    loadingStatus.calendlyLoadingStatus = statusType
  }
  if (payload.calendar === CYCLR_CALENDAR.GOOGLE_CALENDAR) {
    loadingStatus.googleLoadingStatus = statusType
  }
  if (payload.calendar === CYCLR_CALENDAR.MICROSOFT_OFFICE_365) {
    loadingStatus.outLookLoadingStatus = statusType
  }
  return {
    loadingStatus,
  }
}
function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_CONNECTORS.FULLFILLED:
      return {
        ...state,
        userConnectors: action.payload,
      }
    case SET_AUTH_TOOLS_SUCCESS.STARTED:
      return {
        ...state,
        calendarConnectionSuccess: false,
        isToolConnectionSuccessLoading: true,
      }
    case SET_AUTH_TOOLS_SUCCESS.FULLFILLED:
      return {
        ...state,
        calendarConnectionSuccess: true,
        isToolConnectionSuccessLoading: false,
      }
    case SET_AUTH_TOOLS_SUCCESS.REJECTED:
      return {
        ...state,
        isToolConnectionSuccessLoading: false,
      }
    case GET_OAUTH_URL.STARTED:
      return {
        ...state,
        ...getOAuthLoading(state, action.payload, STATUS.LOADING),
      }
    case GET_OAUTH_URL.FULLFILLED:
      return {
        ...state,
        oAuthUrl: action.payload.url,
        ...getOAuthLoading(state, action.payload, STATUS.REJECTED),
      }
    case GET_OAUTH_URL.REJECTED:
      return {
        ...state,
        ...getOAuthLoading(state, action.payload, STATUS.COMPLETED),
      }
    case GET_USER_CALENDAR_EVENTS.FULLFILLED:
      return {
        ...state,
        calendarEvents: action.payload,
      }
    case GET_USER_CALENDAR_EVENTS.RESET:
      return {
        ...state,
        calendarEvents: [],
      }
    case CANCEL_USER_CALENDAR_EVENT.REJECTED:
    case CANCEL_USER_CALENDAR_EVENT.FULLFILLED:
      return {
        ...state,
        cancelMeetingLoading: false,
        status: STATUS.COMPLETED,
      }
    case CANCEL_USER_CALENDAR_EVENT.STARTED:
      return {
        ...state,
        cancelMeetingLoading: true,
        status: STATUS.LOADING,
      }
    case SET_USER_MEETING.FULLFILLED:
      return {
        ...state,
        isMeetingModalOpen: false,
        isScheduled: true,
        isDisabled: false,
      }
    case SET_USER_MEETING.REJECTED:
      return {
        ...state,
        errorMessage: action.error,
        isMeetingModalOpen: false,
        isDisabled: false,
      }
    case SET_USER_MEETING.STARTED:
      return {
        ...state,
        isMeetingModalOpen: true,
        isDisabled: true,
        errorMessage: '',
      }
    case GET_CALENDLY_USER.FULLFILLED:
      return {
        ...state,
        calendlyCalendar: action.payload,
      }
    case RESET_OAUTH_URL:
      return {
        ...state,
        oAuthUrl: '',
      }
    case GET_BO_ADVISOR.STARTED:
      return {
        ...state,
        boAdvisorsLoading: true,
      }
    case GET_BO_ADVISOR.FULLFILLED:
      return {
        ...state,
        boAdvisors: action.payload,
        boAdvisorsLoading: false,
      }

    case GET_USER_MEETING.FULLFILLED:
      return {
        ...state,
        isScheduled: action.payload,
      }
    case HANDLE_CYCLR_OAUTH.FULLFILLED:
      return {
        ...state,
        isConnected: action.payload.isConnected,
        oAuthUrl: action.payload.url,
      }
    case UPDATE_CALENDAR_MEETING.FULLFILLED:
      return {
        ...state,
        updatedCalendar: action.payload,
      }
    case GET_MEETING_TYPES.FULLFILLED:
      return {
        ...state,
        meetingTypes: action.payload,
      }
    case SET_USER_AVAILABILITY.STARTED:
      return {
        ...state,
        isSlotsModalOpen: true,
      }
    case SET_USER_AVAILABILITY.FULLFILLED:
      return {
        ...state,
        userAvailability: action.payload,
      }
    case GET_USER_AVAILABILITY.STARTED:
      return {
        ...state,
        userAvailabilityList: [],
        userAvailabilityStatus: 'loading',
      }
    case GET_USER_AVAILABILITY.FULLFILLED:
      return {
        ...state,
        userAvailabilityList: action.payload,
        userAvailabilityStatus: 'success',
      }
    case GET_USER_AVAILABILITY.REJECTED:
      return {
        ...state,
        userAvailabilityList: [],
        userAvailabilityStatus: 'failed',
      }
    case OPEN_SLOTS_MODAL:
      return {
        ...state,
        isSlotsModalOpen: false,
      }
    case UPDATE_MEETING_STEPPER:
      if (action.payload.actionType === 'increment') {
        return {
          ...state,
          meetingStepper: (state.meetingStepper = action.payload.stepName),
        }
      } else if (action.payload.actionType === 'decrement') {
        const currentIndex = Object.values(MEETING_STEPPER).indexOf(state.meetingStepper)
        const previousIndex = currentIndex > 0 ? currentIndex - 1 : 0
        const previousStep = Object.values(MEETING_STEPPER)[previousIndex]
        return {
          ...state,
          meetingStepper: previousStep,
        }
      } else if (action.payload.actionType === 'reset') {
        return {
          ...state,
          meetingStepper: (state.meetingStepper = action.payload.stepName),
          userAvailabilityList: [],
        }
      } else {
        return state
      }
    case GET_USER_AVAILABILITY_HOURS.FULLFILLED:
      return {
        ...state,
        userAvailabilityHoursList: action.payload,
      }
    case UPDATE_USER_AVAILABILITY_HOURS.STARTED:
      return {
        ...state,
        isUpdateUserAvailabilityLoading: true,
      }
    case UPDATE_USER_AVAILABILITY_HOURS.FULLFILLED:
    case UPDATE_USER_AVAILABILITY_HOURS.REJECTED:
      return {
        ...state,
        isSlotsModalOpen: true,
        isUpdateUserAvailabilityLoading: false,
      }
    case RESET_PLAY:
      return {
        ...state,
        isConnected: false,
        isScheduled: false,
      }

    case SET_IS_SCHEDULED:
      return {
        ...state,
        isScheduled: action.payload,
      }
    case GET_ADVISOR_PROFILE.FULLFILLED:
      return {
        ...state,
        advisorProfile: action.payload,
      }
    case GET_USER_MEETING_BY_EVENT_ID.STARTED:
      return {
        ...state,
        calendarEvent: {},
      }
    case GET_USER_MEETING_BY_EVENT_ID.FULLFILLED:
      return {
        ...state,
        calendarEvent: action.payload,
      }
    case SET_TOOL_CONNECTION_STATUS:
      return {
        ...state,
        isConnected: action.payload.isConnected,
        userConnectors: JSON.parse(
          JSON.stringify(
            state.userConnectors.map((connector: { name: string; authenticated: boolean }) => {
              if (connector.name === action.payload.toolName) {
                return { ...connector, authenticated: action.payload.isConnected }
              }
              return connector
            })
          )
        ),
      }
    case OPEN_MEETING_CONFIRMATION:
      return {
        ...state,
        errorMessage: action.payload.error,
        stepType: action.payload.stepType,
        isMeetingDeletion: action.payload?.isMeetingDeletion,
      }

    case SET_CURRENT_CALENDAR_DATE:
      return {
        ...state,
        currentCalendarDate: action.payload,
      }
    default:
  }
  return state
}

export default reducer
