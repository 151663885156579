import moment from 'moment'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import UserApi from 'api/user'
import GoalApi from 'api/goal'
import OwnerApi from 'api/ownerData'
import isNil from 'lodash/isNil'

import { getUserGoalAction, updateUserGoalAction } from 'store/actions/owner/playbooks'
import {
  getMarketingChartDataAction,
  getCashBalanceChartDataAction,
  getCashBalanceByMonthChartDataAction,
  getLiquidCashChartDataAction,
  getProfitAndLossChartDataAction,
} from 'store/actions/owner/ownerData'
import {
  GET_ASSESSMENT_QUESTION,
  GET_USER_ASSESSMENT_RESPONSE,
  SAVE_ASSESSMENT_RESPONSE,
  GET_USER_GOAL,
  UPDATE_USER_GOAL,
  ADD_EDIT_ASSESSMENT,
  HANDLE_GOAL_ACTION,
  GET_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
  GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION,
  PREVIOUS_QUESTION,
  OWNER_ONBOARDING_COMPLETED,
  GET_MARKETING_DATA,
  GET_ASSESSMENT_OPTIONS,
  GET_CASH_BALANCE_DATA,
  GET_CASH_BALANCE_BY_MONTH_DATA,
  GET_LIQUID_CASH_DATA,
  GET_PROFIT_AND_LOSS_DATA,
} from 'store/types'
import AssessmentApi from 'api/assessment'
import {
  getAssessmentQuestionAction,
  saveAssessmentResponseAction,
  getUserAssessmentResponseAction,
  addEditAssessmentAction,
  setActiveQuestion,
  setActiveStepAction,
  getUsersAssessmentResponseByQuestionAction,
  getAssessmentResponseByQuestionAction,
  initializeOwnerStepperAction,
  getAssessmentOptionsAction,
} from 'store/actions/owner/initialAssessment'

import { getRecommendedPlaysAssessments, showMyProfileAction } from 'store/actions/owner/account'
import { LOG_STATUS, ASSESSMENTS, assessmentOption } from 'config'
import { get, reverse, cloneDeep, find, includes, isEmpty, sortBy } from 'lodash'
import { ASSESSMENT_USER_PRIVACY, ROLES } from 'config/enums'
import {
  extractAssessmentSubString,
  fillAssessmentWithUpdatedOptions,
  getRoleFromPath,
  navigateTo,
} from 'utils/helper'
import { getAssessmentQuestionSelector, getCurrentGoalSelector } from 'store/selectors/owner'
import { GOAL_ACTION } from 'config'
import { resetProgressbarAction } from 'store/actions/common/progressbar'
import { setFormData } from 'store/actions/form'
import { getAssessmentPlayListingAction, getDashboardListingAction } from 'store/actions/PlayBook'
import { checkBusinessProfileAction } from 'store/actions/userManagement'
import {
  AccountingChartResponse,
  AccountingCharts,
  ErrorType,
} from '../../__generated__/api-types-and-hooks'
import { getParsedAssessmentResponse } from 'hooks/useUserAssessmentResponse'
import { ampli } from 'ampli'
import { logoutAction } from 'store/actions/auth'

export const getUserAssessments = (assessments, filter = true) => {
  let userAssessments

  userAssessments = assessments = get(assessments, 'getAssessments', [])
  userAssessments = userAssessments.map((assessment) => ({
    ...assessment,
    meta: assessment?.meta ? JSON.parse(assessment.meta) : null,
    content: assessment?.content ? JSON.parse(assessment.content) : null,
    options: assessment?.options ? JSON.parse(assessment.options) : null,
    previous: assessment?.previous ? JSON.parse(assessment.previous) : [],
  }))

  if (filter) {
    const role = getRoleFromPath()
    if (role) {
      userAssessments = userAssessments.filter(
        (assessment) => assessment?.meta?.[ASSESSMENT_USER_PRIVACY[role]]
      )
    }
  }

  return userAssessments
}

function* getAssessmentOptions(action) {
  const res = yield call(AssessmentApi.getAssessmentOptions, action.payload)

  yield put(getAssessmentOptionsAction.FULLFILLED(res?.getOptions?.data))
}

function* getAssessments(action) {
  const activeQuestion = yield select((state) => state.owner.activeQuestion)

  let getAssessments = yield call(AssessmentApi.getAssessments, action.payload.type)
  getAssessments = getUserAssessments(getAssessments, action.payload?.filter)

  getAssessments = sortBy(getAssessments, (q) => q.previous)
  yield put(getAssessmentQuestionAction.FULLFILLED(getAssessments))
  if (!activeQuestion.id) yield put(setActiveQuestion(getAssessments[0]?.id))
}

function* getUserAssessmentResponse(action) {
  const assessmentQuestions = yield select((state) => state.owner.questions)

  const { type, setForms, checkForAssessment } = action.payload
  yield put(checkBusinessProfileAction(false))
  const res = yield call(AssessmentApi.getUserAssessmentResponse, type, action?.payload?.userId)
  const response = yield call(UserApi.getBusinessProfile, action?.payload?.userId)
  const businessProfile = response.getBusinessProfile

  const assessmentResponses = getParsedAssessmentResponse(
    get(res, 'getUserAssessmentResponse.data', '')
  )
  const assessmentResponse = {}
  assessmentResponses.assessmentResponse = assessmentResponses.assessmentResponse || []
  assessmentResponses.assessmentResponse.forEach((answer) => {
    assessmentResponse[answer.id] = answer.value
  })
  if (setForms?.length > 0) {
    if (checkForAssessment) {
      const businessAssessment = {}
      const businessQuestions = assessmentQuestions.filter(
        (question) => question?.meta?.showToBusinessProfile
      )

      for (let i = 0; i < businessQuestions.length; i++) {
        businessAssessment[businessQuestions[i].id] = assessmentResponse[businessQuestions[i].id]
      }
      yield put(setFormData({ form: setForms[0], data: businessAssessment }))
    } else {
      yield put(setFormData({ form: setForms[0], data: assessmentResponse }))
    }
  }
  const assessmentSubString = extractAssessmentSubString(type)
  const optionIds = assessmentOption[assessmentSubString]
  if (optionIds) {
    const getOptionsResponse = yield call(AssessmentApi.getAssessmentOptions, optionIds)

    const assessmentOptions = getOptionsResponse?.getOptions?.data
    yield put(getAssessmentOptionsAction.FULLFILLED(getOptionsResponse?.getOptions?.data))
    assessmentResponses.assessmentResponse = fillAssessmentWithUpdatedOptions(
      assessmentResponses,
      assessmentQuestions,
      assessmentOptions,
      businessProfile,
      type
    )
  }

  yield put(getUserAssessmentResponseAction.FULLFILLED(assessmentResponses))
  yield put(checkBusinessProfileAction(true))
  // if (questions.currentStage) {
  //   yield put(setActiveStepAction('Questions'))
  //   yield put(setActiveQuestion(questions.currentStage))
  // }
}

function* addEditAssessment(action) {
  const steps = yield select((state) => state.owner.steps)
  const id = yield select((state) => state.user.user.id)
  const { type, userId } = action.payload
  const response = yield call(UserApi.getBusinessProfile, id)
  let assessmentQuestions = yield call(AssessmentApi.getAssessments, type)
  assessmentQuestions = getUserAssessments(assessmentQuestions)
  assessmentQuestions = sortBy(assessmentQuestions, (q) => q.previous)

  const businessProfile = response?.getBusinessProfile
  let assessmentResponses: any = { assessmentResponse: [] }

  const res = yield call(AssessmentApi.getUserAssessmentResponse, type, userId)
  assessmentResponses = get(res, 'getUserAssessmentResponse.data', {})
    ? getParsedAssessmentResponse(get(res, 'getUserAssessmentResponse.data', {}))
    : {}
  const getOptionsResponse = yield call(
    AssessmentApi.getAssessmentOptions,
    assessmentOption[extractAssessmentSubString(type)]
  )

  const assessmentResponse = {}
  assessmentResponses.assessmentResponse = assessmentResponses.assessmentResponse || []
  assessmentResponses.assessmentResponse.forEach((answer) => {
    assessmentResponse[answer.id] = answer.value
  })
  const assessmentOptions = getOptionsResponse?.getOptions?.data
  assessmentResponses.assessmentResponse = fillAssessmentWithUpdatedOptions(
    assessmentResponses,
    assessmentQuestions,
    assessmentOptions,
    businessProfile,
    type
  )
  const isAssessmentCompleted =
    !isNil(assessmentResponses.completedAt) && assessmentResponses.completedAt !== ''

  if (type && !isAssessmentCompleted) {
    ampli.assessmentStarted({
      id: type,
      name: ASSESSMENTS[type]?.title || 'Unknown Assessment',
    })
  }

  yield put(getAssessmentOptionsAction.FULLFILLED(getOptionsResponse?.getOptions?.data))
  yield put(
    addEditAssessmentAction.FULLFILLED({
      questions: assessmentQuestions,
      assessmentResponse: assessmentResponses,
    })
  )

  if (assessmentResponses?.currentStage) {
    yield put(setActiveStepAction('Questions'))
    yield put(setActiveQuestion(assessmentResponses.currentStage))
  } else {
    yield put(setActiveStepAction(steps[0].title))
    let firstQuestion = assessmentQuestions.find((assessment) => assessment?.previous.length === 0)
    if (firstQuestion) {
      yield put(setActiveQuestion(firstQuestion.id))
    }
  }
}

function* saveAssessmentResponse(action) {
  const activeQuestionId = action.payload.activeQuestion
  delete action.payload.activeQuestion
  try {
    let actionValue = ''
    const { type, isLastQuestion } = action.payload

    const sessionToken = localStorage.getItem('sessionToken')
    const tenantId = yield select((state) => state.user?.tenantId)

    const question = yield select((state) => state.owner.questions)
    const userId = yield select((state) => state.user.user.id)
    let firstQuestion = question.find((assessment) => assessment?.previous.length === 0)
    const assessmentResponse = yield select((state) => state.owner.assessmentResponse)

    if (isLastQuestion) {
      action.payload.isCompleted = true
    }

    const currentQuestion = action.payload.assessmentResponse
    if (firstQuestion && currentQuestion.id === firstQuestion.id) {
      if (!assessmentResponse.assessmentResponse.find((res) => res.id === firstQuestion.id)) {
        if (
          action?.payload?.type !== 'initial' &&
          ASSESSMENTS[firstQuestion?.assessmentType]?.title
        ) {
          actionValue = `${LOG_STATUS.STARTED} ${ASSESSMENTS[firstQuestion?.assessmentType]?.title}`
          yield call(UserApi.logActivity, {
            action: actionValue,
            logStatus: LOG_STATUS.STARTED,
            accessToken: sessionToken,
            tenantId,
            showClientActivity: true,
            type,
          })
        }
      }
    }
    const navigationId = action.payload.navigationId || ''
    let widget = action.payload.widget
    delete action.payload.isLastQuestion
    delete action.payload.widget
    delete action.payload.navigationId
    action.payload.tenantId = tenantId

    const assessmentResp = yield call(AssessmentApi.saveAssessmentResponse, action.payload)
    if (widget && assessmentResp) {
      yield put(getAssessmentPlayListingAction.STARTED())

      if (isLastQuestion) {
        yield put(getDashboardListingAction.STARTED())
      }
    }

    yield put(getUserAssessmentResponseAction.STARTED({ type, userId }))

    // fetch recommended assessments & plays
    if (isLastQuestion) {
      // logActivity
      const sessionToken = localStorage.getItem('sessionToken')

      const tenantId = yield select((state) => state.user?.tenantId)

      if (ASSESSMENTS[type]?.title) {
        yield call(UserApi.logActivity, {
          action: `${LOG_STATUS.COMPLETED} ${ASSESSMENTS[type]?.title}`,
          logStatus: LOG_STATUS.COMPLETED,
          accessToken: sessionToken,
          tenantId,
          showClientActivity: true,
          type,
        })
      }
    }
    yield put(saveAssessmentResponseAction.FULLFILLED(assessmentResp))
    if (isLastQuestion && navigationId) {
      navigateTo(ROLES.BUSINESS_OWNER, navigationId)
    }
  } catch (error) {
    console.log('get assessment question error : ', error)

    if (get(error, 'message') === ErrorType.ForceLogoutError) {
      yield put(logoutAction())
    }
    yield put(saveAssessmentResponseAction.REJECTED('Please select your answer again.'))
    yield put(setActiveQuestion(activeQuestionId))
  }
}

function* getUserGoal() {
  const goals = yield call(GoalApi.getUserGoals)
  yield put(getUserGoalAction.FULLFILLED(goals))
}

function* updateUserGoal(action) {
  yield call(GoalApi.updateUserGoal, action.payload)
  yield put(updateUserGoalAction.FULLFILLED({ disable: false }))
  yield call(getUserGoal)
}

function* getAssessmentResponseByQuestion(action) {
  const user = yield select((state) => state.user.user)

  const { type, question } = action.payload

  const res = yield call(AssessmentApi.getAssessmentResponseByQuestion, question, type, user.id)
  yield put(
    getAssessmentResponseByQuestionAction.FULLFILLED(res?.getAssessmentResponseByQuestion?.data)
  )
}

function* getUsersAssessmentResponseByQuestion(action) {
  const { type, question, users } = action.payload
  const data = {}
  const res = yield all(
    users.map((x) => call(AssessmentApi.getAssessmentResponseByQuestion, question, type, x))
  )

  for (let i = 0; i < res.length; i++) {
    if (res[i].getAssessmentResponseByQuestion.success) {
      const assessmentQuestion = res[i].getAssessmentResponseByQuestion.data
      const value = assessmentQuestion?.value

      let parsedQuestion = assessmentQuestion?.question || []
      const selectedOption = parsedQuestion?.options?.find((opt) => opt.id === value)
      data[users[i]] = { icon: selectedOption?.icon, value: selectedOption?.value }
    }
  }
  yield put(getUsersAssessmentResponseByQuestionAction.FULLFILLED(data))
}

/* ---------------------------- Component Helpers ---------------------------- */

function* handleUserGoalAction(action) {
  const { type } = action.payload

  const goals = yield select((state) => state.owner.goals.getUserGoals)
  const assessment = yield select(getAssessmentQuestionSelector)
  const currentGoal = yield select(getCurrentGoalSelector)

  let dataToUpdate = {}
  if ([GOAL_ACTION.ACTIVE, GOAL_ACTION.INACTIVE].includes(type)) {
    const remainingActiveGoals = goals
      .filter(
        (res) =>
          (type === GOAL_ACTION.ACTIVE && (res.isActiveGoal || res.id === currentGoal.id)) ||
          (type === GOAL_ACTION.INACTIVE && res.isActiveGoal && res.id !== currentGoal.id)
      )
      .map((d) => d.id)
    dataToUpdate = { id: 'activeGoal', isActiveGoal: true, value: remainingActiveGoals }
  }

  if (type === GOAL_ACTION.TOP_GOAL) {
    const questionId = assessment?.questions?.find((res) => res?.meta?.isTopGoal)?.id
    dataToUpdate = { id: questionId, isTopGoal: true, value: currentGoal.id }
  }
  const assessmentResponseData: any = {
    assessmentResponse: [
      {
        ...dataToUpdate,
      },
    ],
    type: 'initial',
  }
  yield call(updateUserGoal, { payload: assessmentResponseData })
}

function* getRecommendedPlaysAssessment(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const res = yield call(AssessmentApi.getUserRecommendations, {
      tenantId,
      assessmentType: action.payload,
    })
    let record = JSON.parse(res?.getUserRecommendations?.data || '{}')
    yield put(getRecommendedPlaysAssessments.FULLFILLED(record?.recommendations || []))
  } catch (error) {
    yield put(getRecommendedPlaysAssessments.REJECTED(error))
    console.log('recommended play assessment error ', error)
  }
}

function* previousQuestion(action) {
  // notes: (alihamza): save errors for troubleshooting

  let { previous, activeQuestionId } = action.payload
  const questions = yield select((state) => state.owner.questions)
  const assessmentResponse = yield select(
    (state) => state.owner.assessmentResponse?.assessmentResponse
  )

  if (previous.length === 1) {
    const previousQuestion = questions.find((obj) => previous[0] === obj.id)
    yield put(setActiveQuestion(previousQuestion.id))
  } else {
    let answers: any = [] //those questions who answer is saved
    previous.forEach((question_id) => {
      assessmentResponse.forEach((obj) => {
        if (!isEmpty(obj.value) && question_id.trim() === obj.id.trim()) {
          if (obj.next === activeQuestionId) {
            answers.push(obj)
          }
        }
      })
    })

    if (answers.length === 1) {
      yield put(setActiveQuestion(answers[0].id))
    } else {
      let previous_question_id = ''
      let previous_question

      // iterate answers (answer saved in DB)
      answers.some((answer) => {
        let question = questions.find((obj) => answer.id.trim() === obj.id.trim())
        let selectedNextQuestionId
        assessmentResponse.forEach((response) => {
          if (response.id === question.id) {
            question.options.forEach((option) => {
              if (question?.optionType !== 'input' && option.id === response?.value) {
                previous_question = question
                selectedNextQuestionId = option?.action?.next?.default
              }
            })
          }
        })
        if (!previous_question) return ''

        let nextQuestionId: any = ''

        // find next questionId
        if (question) {
          if (question?.optionType === 'input') {
            nextQuestionId = question?.options[0]?.action?.next?.default
          }
          if (previous_question && selectedNextQuestionId === activeQuestionId) {
            nextQuestionId = selectedNextQuestionId
            previous_question_id = previous_question.id
          }
        } else {
          question.options.forEach((option) => {
            if (option.id === JSON.parse(answer.value)) {
              nextQuestionId = option?.action?.next?.default
              return option?.action?.next?.default
            }
          })
        }

        // check next questionID is equal to activeQuestionId
        if (nextQuestionId.trim() === activeQuestionId.trim()) {
          previous_question_id = answer.id
          return true
        } else {
          return false
        }
      })
      if (previous_question_id === '')
        previous_question_id = get(
          find(reverse(cloneDeep(assessmentResponse)), ({ id }) => includes(previous, id)),
          'id'
        )

      yield put(setActiveQuestion(previous_question_id))
    }
  }
}

function* ownerOnBoardingCompletion(action) {
  const user = yield select((state) => state.user.user)
  const tenantId = yield select((state) => state.user?.tenantId)
  yield call(UserApi.updateUserProfile, {
    isNewUser: false,
    tenantId,
  })

  yield put(showMyProfileAction(false))
  yield call(UserApi.updateBusinessProfile, {
    id: user.id,
    isOnboarded: true,
    tenantId,
  })

  yield put(initializeOwnerStepperAction([]))
  yield put(resetProgressbarAction())
  yield put(setActiveStepAction(''))

  // log onBoarding completion
  const sessionToken = localStorage.getItem('sessionToken')
  yield call(UserApi.logActivity, {
    action: `Onboarding ${LOG_STATUS.COMPLETED}`,
    logStatus: LOG_STATUS.COMPLETED,
    accessToken: sessionToken,
    tenantId,
    showClientActivity: true,
  })
  navigateTo(ROLES.BUSINESS_OWNER, 'dashboard')
}

function* getMarketingChartData(action) {
  const res = yield call(OwnerApi.getOwnerBusinessData, action.payload)
  const data = JSON.parse(res.getOwnerBusinessData.data)
  yield put(
    getMarketingChartDataAction.FULLFILLED({
      ...data,
      updatedDate: moment().format('YYYY-MM-DD'),
    })
  )
}

function* getCashBalanceChartData(action) {
  const res = yield call(OwnerApi.getOwnerAccountingData, {
    ...action.payload,
    chartType: AccountingCharts.CashBalance,
  })
  const chartData: AccountingChartResponse = res?.getAccountingCharts
  yield put(getCashBalanceChartDataAction.FULLFILLED(chartData))
}

function* getCashBalanceByMonthChartData(action) {
  const res = yield call(OwnerApi.getOwnerAccountingData, {
    ...action.payload,
    chartType: AccountingCharts.CashBalanceByMonth,
  })
  const chartData: AccountingChartResponse = res?.getAccountingCharts
  yield put(getCashBalanceByMonthChartDataAction.FULLFILLED(chartData))
}

function* getLiquidCashChartData(action) {
  const res = yield call(OwnerApi.getOwnerAccountingData, {
    ...action.payload,
    chartType: AccountingCharts.LiquidCash,
  })
  const chartData: AccountingChartResponse = res?.getAccountingCharts
  yield put(getLiquidCashChartDataAction.FULLFILLED(chartData))
}

function* getProfitAndLossChartData(action) {
  const res = yield call(OwnerApi.getOwnerAccountingData, {
    ...action.payload,
    chartType: AccountingCharts.ProfitAndLoss,
  })
  const chartData: AccountingChartResponse = res?.getAccountingCharts
  yield put(getProfitAndLossChartDataAction.FULLFILLED(chartData))
}

/* -------------------------------- Watchers -------------------------------- */
export function* watcherAssessment() {
  yield takeLatest(GET_ASSESSMENT_OPTIONS.STARTED, getAssessmentOptions)
  yield takeLatest(GET_ASSESSMENT_QUESTION.STARTED, getAssessments)
  yield takeLatest(ADD_EDIT_ASSESSMENT.STARTED, addEditAssessment)
  yield takeLatest(GET_USER_GOAL.STARTED, getUserGoal)
  yield takeLatest(UPDATE_USER_GOAL.STARTED, updateUserGoal)
  yield takeLatest(SAVE_ASSESSMENT_RESPONSE.STARTED, saveAssessmentResponse)
  yield takeLatest(GET_USER_ASSESSMENT_RESPONSE.STARTED, getUserAssessmentResponse)

  yield takeLatest(HANDLE_GOAL_ACTION, handleUserGoalAction)
  yield takeLatest(GET_ASSESSMENT_RESPONSE_BY_QUESTION.STARTED, getAssessmentResponseByQuestion)
  yield takeLatest(
    GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.STARTED,
    getUsersAssessmentResponseByQuestion
  )
  yield takeLatest(GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED, getRecommendedPlaysAssessment)
  yield takeLatest(PREVIOUS_QUESTION, previousQuestion)
  yield takeLatest(OWNER_ONBOARDING_COMPLETED, ownerOnBoardingCompletion)
  yield takeLatest(GET_MARKETING_DATA.STARTED, getMarketingChartData)
  yield takeLatest(GET_CASH_BALANCE_DATA.STARTED, getCashBalanceChartData)
  yield takeLatest(GET_CASH_BALANCE_BY_MONTH_DATA.STARTED, getCashBalanceByMonthChartData)
  yield takeLatest(GET_LIQUID_CASH_DATA.STARTED, getLiquidCashChartData)
  yield takeLatest(GET_PROFIT_AND_LOSS_DATA.STARTED, getProfitAndLossChartData)
}
